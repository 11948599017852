import { ReactNode, createContext } from "react";
import useExcel from "./hooks/useExcel";

interface ExcelContextProviderProps {
    children: ReactNode;
}

export interface ExcelContextData {
    downloadPrecoCustoMargemCombustivelExcel: (idEmpresa: number, anoVigencia: number, mesReferencia: string) => void;
    downloadVendasTotaisProdutosQuantidadeExcel: (idEmpresa: number, anovigencia: number, mesReferencia: string) => void;
    downloadMixProdutosExcel: (idEmpresa: number, anovigencia: number, mesReferencia: string) => void;
    downloadPrecoCustoMargemProdutosExcel: (idEmpresa: number, anovigencia: number, mesReferencia: string) => void;
}

const initialState: ExcelContextData = {
    downloadPrecoCustoMargemCombustivelExcel: async () => { },
    downloadVendasTotaisProdutosQuantidadeExcel: async () => { },
    downloadMixProdutosExcel: async () => { },
    downloadPrecoCustoMargemProdutosExcel: async () => { },
};

export const ExcelContext =
    createContext<ExcelContextData>(initialState);

export default function ExcelContextProvider({
    children,
}: ExcelContextProviderProps) {
    const {
        downloadPrecoCustoMargemCombustivelExcel,
        downloadVendasTotaisProdutosQuantidadeExcel,
        downloadMixProdutosExcel,
        downloadPrecoCustoMargemProdutosExcel,
    } = useExcel();

    return (
        <ExcelContext.Provider
            value={{
                downloadPrecoCustoMargemCombustivelExcel,
                downloadVendasTotaisProdutosQuantidadeExcel,
                downloadMixProdutosExcel,
                downloadPrecoCustoMargemProdutosExcel,
            }}
        >
            {children}
        </ExcelContext.Provider>
    );
}
