import React, { useContext } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import { Container } from "reactstrap";
import AdminFooter from "../../components/Footers/AdminFooter";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./Admin.css";
import { routes } from "../../routes.js";
import { routesRecruiter } from "../../routes.js";
import { routesAdmin } from "../../routes.js";
import { AuthContext } from "../../context/AuthContext";
import { version } from "../../version";

const Admin = (props: any) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const { selectTypeUser } = useContext(AuthContext);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document && document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContent && mainContent.current) {
      //@ts-ignore
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: number) => {
      if (prop.layout === "/admin") {
        return (
          // @ts-ignore
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={selectTypeUser === 2 ? routesRecruiter : selectTypeUser === 3 ? routesAdmin : routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logoAtt.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <Routes>
          {getRoutes(selectTypeUser === 2 ? routesRecruiter : selectTypeUser === 3 ? routesAdmin : routes)}
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;