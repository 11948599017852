import { useContext } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";

export default function useExcel() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);

    const downloadExcel = async (endpoint: string, idEmpresa: number, anoVigencia: number, mesReferencia: string, name: string) => {
        setLoading(true);
        const url = `Excel/${endpoint}?idEmpresa=${idEmpresa}&anoVigencia=${anoVigencia}&mesReferencia=${mesReferencia}`;
        try {
            const response = await api.get(url, { responseType: 'blob' });
            const contentDisposition = response.headers['content-disposition'];
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
                if (fileNameMatch && fileNameMatch.length === 2) name = fileNameMatch[1];
            }
            const blobUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = name;
            document.body.appendChild(link);
            link.click();
            link.remove();
    
            showToast("success", `Arquivo ${name} baixado com sucesso!`);
        } catch (error: any) {
            showToast("error", `Erro ao baixar o arquivo: ${error.response?.data || error.message}`);
        } finally {
            setLoading(false);
        }
    };
    

    const downloadPrecoCustoMargemCombustivelExcel = (idEmpresa: number, anoVigencia: number, mesReferencia: string) => downloadExcel("GetPrecoCustoMargemCombustivelExcel", idEmpresa, anoVigencia, mesReferencia, "Margem Combustível " + anoVigencia);
    const downloadVendasTotaisProdutosQuantidadeExcel = (idEmpresa: number, anoVigencia: number, mesReferencia: string) => downloadExcel("GetVendasTotaisProdutosQuantidadeExcel", idEmpresa, anoVigencia, mesReferencia, "Vendas de Produtos em Quantidade " + anoVigencia);
    const downloadMixProdutosExcel = (idEmpresa: number, anoVigencia: number, mesReferencia: string) => downloadExcel("GetMixProdutosExcel", idEmpresa, anoVigencia, mesReferencia, "Produtos sem venda " + anoVigencia);
    const downloadPrecoCustoMargemProdutosExcel = (idEmpresa: number, anoVigencia: number, mesReferencia: string) => downloadExcel("GetPrecoCustoMargemProdutosExcel", idEmpresa, anoVigencia, mesReferencia, "Percentual Margem Produtos "+ anoVigencia);

    return {
        downloadPrecoCustoMargemCombustivelExcel,
        downloadVendasTotaisProdutosQuantidadeExcel,
        downloadMixProdutosExcel,
        downloadPrecoCustoMargemProdutosExcel
    };
}
