import { ReactNode, createContext } from "react";
import useFile from "./hooks/useFile";

interface FileContextProviderProps {
    children: ReactNode;
}

export interface FileContextData {
    uploadFile: (file: File, network: string, empresa: string, ano: string, mes: string) => Promise<any>;
    uploadResults?: any;
    isModalOpen?: boolean;
    setIsModalOpen?: (isOpen: boolean) => void;
}

const initialState: FileContextData = {
    uploadFile: async () => {
        return Promise.resolve(null);
    },
    uploadResults: null,
    isModalOpen: false,
    setIsModalOpen: () => {},
};

export const FileContext =
    createContext<FileContextData>(initialState);

export default function FileContextProvider({
    children,
}: FileContextProviderProps) {
    const {
        uploadFile,
        uploadResults,
        isModalOpen,
        setIsModalOpen
    } = useFile();

    return (
        <FileContext.Provider
            value={{
                uploadFile,
                uploadResults,
                isModalOpen,
                setIsModalOpen,
            }}
        >
            {children}
        </FileContext.Provider>
    );
}
