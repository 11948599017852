// import {
//     Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
//     FormGroup, Label, Input
// } from "reactstrap";
// import { useContext, useEffect, useState } from "react";
// import Header from "../../components/Headers/Header";
// import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
// import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
// import { EmpresasContext } from "../../context/EmpresasContext";
// import { PresentationContext } from "../../context/PresentationContext";
// import { PresentationType, AdditionalPages } from "../../context/hooks/usePresentation";

// const Consolidation = () => {
//     const [loading, setLoading] = useState(false);
//     const { generatePdf } = useContext(PresentationContext);
//     const { listEmpresas, getAllNetworks, getEmpresasByNetwokr, listNetworks, setListEmpresas } = useContext(EmpresasContext);
//     const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);

//     // const [selectedNetwork, setSelectedNetwork] = useState<string | null>(
//     //     () => localStorage.getItem('selectedNetwork')
//     // );

//     const [selectedMonth, setSelectedMonth] = useState<string>('');

//     // const [selectedMonth, setSelectedMonth] = useState<string>(
//     //     () => localStorage.getItem('selectedMonth') || "");

//     const [hasFinalConsolidation, setHasFinalConsolidation] = useState(false);
//     const [addPageModalOpen, setAddPageModalOpen] = useState(false);
//     const [editPageModalOpen, setEditPageModalOpen] = useState(false);

//     const [currentPageTitle, setCurrentPageTitle] = useState('');
//     const [currentPageContent, setCurrentPageContent] = useState('');
//     const [imagePageContent, setImagePageContent] = useState<string | ''>('');

//     // const [additionalPages, setAdditionalPages] = useState<{ [key: number]: AdditionalPages[] }>({});

//     const [additionalPages, setAdditionalPages] = useState<{
//         [key: number]: AdditionalPages[];
//     }>(() => JSON.parse(localStorage.getItem("additionalPages") || "{}"));

//     const [currentEmpresaId, setCurrentEmpresaId] = useState<number | 0>(0);
//     const [editPageIndex, setEditPageIndex] = useState<number | null>(null);
//     const [pageModalOpen, setPageModalOpen] = useState(false);

//     // const [generatePages, setGeneratePages] = useState<{ [key: number]: number[] }>({});

//     const [generatePages, setGeneratePages] = useState<{
//         [key: number]: number[];
//     }>(() => JSON.parse(localStorage.getItem("generatePages") || "{}"));

//     const [conveniencePages, setConveniencePages] = useState<{ [key: number]: boolean }>({});


//     const pageNames = [
//         "Capa",
//         "Período",
//         "Vendas de Combustível em Quantidade",
//         "Vendas de Combustível em Valor",
//         "Percentual Margem Combustível",
//         "% de Vendas de Combustível em Quantidade",
//         "Venda de Produtos em Quantidade",
//         "Produtos sem Venda",
//         "Produtos em Estoque Pista",
//         "Percentual Margem Produtos Pista",
//         "Vendas Produtos com Margem Negativa Pista",
//         "Variação LMC",
//         "Evoluções Despesas",
//         "Percentual de Representatividade das Despesas",
//         "Evoluções Receitas Não Operacionais",
//         "Demonstração do Resultado",
//         "Demonstração do Resultado do Mês",
//         "Relatório Posição de Liquidez",
//         "Fluxo De Caixa"
//     ];

//     useEffect(() => {
//         // localStorage.setItem('listEmpresas', JSON.stringify(listEmpresas));
//         localStorage.setItem('additionalPages', JSON.stringify(additionalPages));
//         localStorage.setItem('generatePages', JSON.stringify(generatePages));
//         localStorage.setItem('selectedNetwork', selectedNetwork || "");
//         // localStorage.setItem('selectedMonth', selectedMonth);

//     }, [additionalPages, listEmpresas, selectedMonth, selectedNetwork, generatePages])

//     useEffect(() => {
//         getAllNetworks();
//         if (listEmpresas.length > 0) {
//             const initialGeneratePages: { [key: number]: number[] } = {};
//             const initialConveniencePages: { [key: number]: boolean } = {};
//             listEmpresas.forEach(empresa => {
//                 initialGeneratePages[empresa.id] = Array.from({ length: 19 }, (_, i) => i + 1);
//                 initialConveniencePages[empresa.id] = empresa.hasConvenience || false;
//             });

//             if(localStorage.getItem('generatePages') == "{}") {
//                 setGeneratePages(initialGeneratePages);
//             }

//             // setGeneratePages(initialGeneratePages);
//             setConveniencePages(initialConveniencePages);
//         }
//     }, [listEmpresas]);

//     const handleNetworkChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//         const network = event.target.value;
//         setSelectedNetwork(network);
//         setLoading(true);
//         await getEmpresasByNetwokr(network);
//         setLoading(false);
//     };

//     const handleGeneratePdf = async () => {
//         setLoading(true);
//         if (selectedNetwork && selectedMonth) {
//             const empresas: PresentationType[] = listEmpresas.map(empresa => ({
//                 id: empresa.id,
//                 name: empresa.name,
//                 generatePages: generatePages[empresa.id] || Array.from({ length: 19 }, (_, i) => i + 1),
//                 additionalPages: additionalPages[empresa.id] || [],
//                 hasConvenience: conveniencePages[empresa.id] || false,
//             }));

//             try {
//                 await generatePdf(empresas, selectedMonth, hasFinalConsolidation);
//             } catch (error) {
//                 console.error('Erro ao gerar PDF:', error);
//             }
//             finally {
//                 setLoading(false);
//             }
//         }
//     };

//     const toggleAddPageModal = () => {
//         setAddPageModalOpen(!addPageModalOpen);
//         setCurrentPageTitle('');
//         setCurrentPageContent('');
//         setImagePageContent('');
//     };

//     const toggleEditPageModal = () => {
//         setEditPageModalOpen(!editPageModalOpen);
//         setCurrentPageTitle('');
//         setCurrentPageContent('');
//         setImagePageContent('');
//         setEditPageIndex(null);
//     };

//     const openEditModalForEmpresa = (empresaId: number, pageIndex: number) => {
//         setCurrentEmpresaId(empresaId);
//         const page = additionalPages[empresaId][pageIndex];
//         setCurrentPageTitle(page.title);
//         setCurrentPageContent(page.content ? page.content : '');
//         setImagePageContent(page.imagem ? page.imagem : '');
//         setEditPageIndex(pageIndex);
//         setEditPageModalOpen(true);
//     };

//     const openAddModalForEmpresa = (empresaId: number) => {
//         setCurrentEmpresaId(empresaId);
//         setAddPageModalOpen(true);
//     };

//     const handleAddPage = () => {
//         if (currentPageTitle && currentEmpresaId !== null) {
//             const newPage: AdditionalPages = {
//                 index: 20 + (additionalPages[currentEmpresaId]?.length || 0),
//                 title: currentPageTitle,
//                 content: currentPageContent ? currentPageContent : '',
//                 imagem: imagePageContent ? imagePageContent : ''
//             };
//             setAdditionalPages(prev => ({
//                 ...prev,
//                 [currentEmpresaId]: [...(prev[currentEmpresaId] || []), newPage],
//             }));
//             setGeneratePages(prev => ({
//                 ...prev,
//                 [currentEmpresaId]: [...(prev[currentEmpresaId] || Array.from({ length: 19 }, (_, i) => i + 1)), 20 + (prev[currentEmpresaId]?.filter(p => p >= 20).length || 0)]
//             }));
//             toggleAddPageModal();
//         }
//     };

//     const handleEditPage = () => {
//         if (currentPageTitle && currentEmpresaId !== null && editPageIndex !== null) {
//             const updatedPages = [...additionalPages[currentEmpresaId]];
//             updatedPages[editPageIndex] = {
//                 index: updatedPages[editPageIndex].index,
//                 title: currentPageTitle,
//                 content: currentPageContent ? currentPageContent : '',
//                 imagem: imagePageContent ? imagePageContent : ''
//             };
//             setAdditionalPages(prev => ({
//                 ...prev,
//                 [currentEmpresaId]: updatedPages,
//             }));
//             // setGeneratePages(prev => ({
//             //     ...prev,
//             //     [currentEmpresaId]: [...(prev[currentEmpresaId] || Array.from({ length: 19 }, (_, i) => i + 1)), 20 + (prev[currentEmpresaId]?.filter(p => p >= 20).length || 0)]
//             // }));
//             toggleEditPageModal();
//         }
//     };

//     const removePage = (empresaId: number, pageIndex: number) => {
//         const updatedPages = additionalPages[empresaId].filter((page, index) => index !== pageIndex);
//         setAdditionalPages(prev => ({
//             ...prev,
//             [empresaId]: updatedPages,
//         }));
//         setGeneratePages(prev => ({
//             ...prev,
//             [empresaId]: prev[empresaId].filter(p => p !== 20 + pageIndex)
//         }));
//     };



//     const removeEmpresa = (id: number) => {
//         const newEmpresas = listEmpresas.filter(empresa => empresa.id !== id);
//         setListEmpresas(newEmpresas);
//     };

//     const moveUp = (index: number) => {
//         if (index > 0) {
//             const newEmpresas = [...listEmpresas];
//             const [movedItem] = newEmpresas.splice(index, 1);
//             newEmpresas.splice(index - 1, 0, movedItem);
//             setListEmpresas(newEmpresas);
//         }
//     };

//     const moveDown = (index: number) => {
//         if (index < listEmpresas.length - 1) {
//             const newEmpresas = [...listEmpresas];
//             const [movedItem] = newEmpresas.splice(index, 1);
//             newEmpresas.splice(index + 1, 0, movedItem);
//             setListEmpresas(newEmpresas);
//         }
//     };

//     const togglePageModal = (empresaId: number) => {
//         setCurrentEmpresaId(empresaId);
//         setPageModalOpen(!pageModalOpen);
//     };

//     const handlePageToggle = (page: number) => {
//         if (currentEmpresaId !== null) {
//             const updatedPages = generatePages[currentEmpresaId] || [];
//             if (updatedPages.includes(page)) {
//                 setGeneratePages(prev => ({
//                     ...prev,
//                     [currentEmpresaId]: updatedPages.filter(p => p !== page),
//                 }));
//             } else {
//                 setGeneratePages(prev => ({
//                     ...prev,
//                     [currentEmpresaId]: [...updatedPages, page],
//                 }));
//             }
//         }
//     };

//     const handleCheckboxChange = (page: number) => {
//         if (currentEmpresaId !== null) {
//             setGeneratePages(prevGeneratePages => {
//                 const updatedPages = [...(prevGeneratePages[currentEmpresaId] || [])];
//                 if (updatedPages.includes(page)) {
//                     return {
//                         ...prevGeneratePages,
//                         [currentEmpresaId]: updatedPages.filter(p => p !== page),
//                     };
//                 } else {
//                     return {
//                         ...prevGeneratePages,
//                         [currentEmpresaId]: [...updatedPages, page],
//                     };
//                 }
//             });
//         }
//     };


//     const handleConvenienceChange = (empresaId: number) => {
//         setConveniencePages(prev => ({
//             ...prev,
//             [empresaId]: !prev[empresaId],
//         }));
//     };

//     return (
//         <>
//             {loading && <LoadingSpinner />}
//             <Header />
//             <AdminNavbar brandText={"Consolidação"} search={false} titleSearch={""} />
//             <Container className="mt-4">
//                 <Row>
//                     <Col md="6">
//                         <div className="mb-3">
//                             <label htmlFor="network-select" className="form-label">Selecione uma Rede:</label>
//                             <select
//                                 id="network-select"
//                                 className="form-control form-control-alternative"
//                                 onChange={handleNetworkChange}
//                                 value={selectedNetwork || ''}
//                             >
//                                 <option value="">Selecione uma rede</option>
//                                 {listNetworks.map((network) => (
//                                     <option key={network.name} value={network.name}>
//                                         {network.name}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>
//                         <div className="mt-3">
//                             <label htmlFor="month-select" className="form-label">Selecione até qual mês deseja o relatório:</label>
//                             <select
//                                 id="month-select"
//                                 className="form-control form-control-alternative"
//                                 onChange={(e) => setSelectedMonth(e.target.value)}
//                                 value={selectedMonth}
//                             >
//                                 <option value="">Selecione um mês</option>
//                                 <option value="Janeiro">Janeiro</option>
//                                 <option value="Fevereiro">Fevereiro</option>
//                                 <option value="Março">Março</option>
//                                 <option value="Abril">Abril</option>
//                                 <option value="Maio">Maio</option>
//                                 <option value="Junho">Junho</option>
//                                 <option value="Julho">Julho</option>
//                                 <option value="Agosto">Agosto</option>
//                                 <option value="Setembro">Setembro</option>
//                                 <option value="Outubro">Outubro</option>
//                                 <option value="Novembro">Novembro</option>
//                                 <option value="Dezembro">Dezembro</option>
//                             </select>
//                         </div>
//                         <FormGroup check className="mt-3">
//                             <Label check>
//                                 <Input
//                                     type="checkbox"
//                                     checked={hasFinalConsolidation}
//                                     onChange={() => setHasFinalConsolidation(!hasFinalConsolidation)}
//                                 />{' '}
//                                 Incluir páginas de consolidação final
//                             </Label>
//                         </FormGroup>
//                     </Col>
//                     {selectedNetwork && selectedMonth && (
//                         <Col md="6" className="d-flex flex-column">
//                             <div className="w-100 mb-3" >
//                                 <div
//                                     className="empresas-list p-3 rounded"
//                                     style={{ backgroundColor: "#1c547c" }}

//                                 >
//                                     <h3 className="mb-3" style={{ color: "#FFF" }}>Empresas na Rede {selectedNetwork}</h3>
//                                     {listEmpresas.map((empresa, index) => (
//                                         <div
//                                             key={empresa.id}
//                                             className="empresa-item bg-white rounded mb-2 p-3 d-flex flex-column"
//                                             draggable
//                                         >
//                                             <div className="d-flex align-items-center mb-2">
//                                                 <span className="drag-handle mr-2">&#8942;</span>
//                                                 <span className="empresa-name flex-grow-1 ms-3">{empresa.name}</span>
//                                                 <button
//                                                     className="btn btn-primary btn-sm ms-2"
//                                                     style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                                     onClick={() => togglePageModal(empresa.id)}
//                                                 >
//                                                     <i className="fas fa-file-alt"></i>
//                                                 </button>
//                                                 <button
//                                                     className="btn btn-primary btn-sm ms-2"
//                                                     style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                                     onClick={() => openAddModalForEmpresa(empresa.id)}
//                                                 >
//                                                     <i className="fas fa-plus"></i>
//                                                 </button>
//                                                 <button
//                                                     className="btn btn-outline-danger btn-sm me-2"
//                                                     onClick={() => removeEmpresa(empresa.id)}
//                                                 >
//                                                     <i className="fas fa-trash-alt"></i>
//                                                 </button>
//                                                 <div className="btn-group">
//                                                     <button
//                                                         className="btn btn-outline-secondary btn-sm"
//                                                         onClick={() => moveUp(index)}
//                                                     >
//                                                         <i className="fas fa-arrow-up"></i>
//                                                     </button>
//                                                     <button
//                                                         className="btn btn-outline-secondary btn-sm"
//                                                         onClick={() => moveDown(index)}
//                                                     >
//                                                         <i className="fas fa-arrow-down"></i>
//                                                     </button>
//                                                 </div>
//                                             </div>
//                                             {empresa.hasConvenience && (
//                                                 <FormGroup check style={{ fontSize: '12px', color: 'grey', textAlign: 'right' }}>
//                                                     <Label check>
//                                                         <Input
//                                                             type="checkbox"
//                                                             checked={conveniencePages[empresa.id] || false}
//                                                             onChange={() => handleConvenienceChange(empresa.id)}
//                                                         />{' '}
//                                                         Habilitar páginas de conveniência
//                                                     </Label>
//                                                 </FormGroup>
//                                             )}
//                                             <div className="additional-pages mt-3">
//                                                 {additionalPages[empresa.id]?.map((page, pageIndex) => (
//                                                     <div key={pageIndex} className="additional-page mb-2 p-3 bg-light rounded">
//                                                         <h5>{page.title}</h5>
//                                                         {/* <p>{page.content}</p> */}
//                                                         <p>
//                                                             {page.content ? page.content : page.imagem ? "Imagem" : ""}
//                                                             {/* {page.content.startsWith('data:image') ? "Imagem" : page.content} */}
//                                                         </p>
//                                                         <div>
//                                                             <button
//                                                                 className="btn btn-primary btn-sm me-2"
//                                                                 style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                                                 onClick={() => openEditModalForEmpresa(empresa.id, pageIndex)}
//                                                             >
//                                                                 <i className="fas fa-edit"></i>
//                                                             </button>
//                                                             <button
//                                                                 className="btn btn-outline-danger btn-sm"
//                                                                 onClick={() => removePage(empresa.id, pageIndex)}
//                                                             >
//                                                                 <i className="fas fa-trash-alt"></i>
//                                                             </button>
//                                                         </div>
//                                                     </div>
//                                                 ))}
//                                             </div>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                             <Button
//                                 style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                 size="m"
//                                 onClick={handleGeneratePdf}
//                                 className="mt-3"
//                             >
//                                 Consolidar relatórios
//                             </Button>
//                         </Col>
//                     )}
//                 </Row>
//             </Container>

//             {/* <Modal isOpen={addPageModalOpen} toggle={toggleAddPageModal} style={{ maxWidth: '100vw', height: '100vh', margin: 0 }}>
//                 <ModalHeader toggle={toggleAddPageModal}>Adicionar Página</ModalHeader>
//                 <ModalBody style={{ height: 'calc(100vh - 150px)' }}>
//                     <FormGroup>
//                         <Label for="content-type-select">Tipo de Conteúdo</Label>
//                         <Input type="select" name="content-type" id="content-type-select" onChange={e => setContentType(e.target.value as 'text' | 'image')}>
//                             <option value="text">Texto</option>
//                             <option value="image">Imagem</option>
//                         </Input>
//                     </FormGroup>
//                     {contentType === 'text' ? (
//                         <>
//                             <FormGroup>
//                                 <Label for="page-title">Título da Página</Label>
//                                 <Input
//                                     type="text"
//                                     id="page-title"
//                                     value={currentPageTitle}
//                                     onChange={e => setCurrentPageTitle(e.target.value)}
//                                 />
//                             </FormGroup>
//                             <FormGroup>
//                                 <Label for="page-content">Conteúdo da Página</Label>
//                                 <Input
//                                     type="textarea"
//                                     id="page-content"
//                                     style={{ height: '90%' }}
//                                     value={currentPageContent}
//                                     rows={17}
//                                     onChange={e => setCurrentPageContent(e.target.value)}
//                                 />
//                             </FormGroup>
//                         </>
//                     ) : (
//                         <>
//                             <FormGroup>
//                                 <Label for="page-title">Título da Página</Label>
//                                 <Input
//                                     type="text"
//                                     id="page-title"
//                                     value={currentPageTitle}
//                                     onChange={e => setCurrentPageTitle(e.target.value)}
//                                 />
//                             </FormGroup>
//                             <FormGroup>
//                                 <Label for="page-image">Imagem da Página</Label>
//                                 <Input
//                                     type="file"
//                                     id="page-image"
//                                     accept="image/*"
//                                     // onChange={e => setUploadedImage(e.target.files ? e.target.files[0] : null)}
//                                     onChange={e => {
//                                         if (e.target.files && e.target.files[0]) {
//                                             const file = e.target.files[0];
//                                             const reader = new FileReader();
//                                             reader.onload = (readEvent) => {
//                                                 if (readEvent.target && readEvent.target.result) {
//                                                     setCurrentPageContent(readEvent.target.result as string);
//                                                 }
//                                             };
//                                             reader.readAsDataURL(file);
//                                         }
//                                     }}
//                                 />
//                             </FormGroup>
//                         </>
//                     )}
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button color="primary" style={{ backgroundColor: "#1c547c" }} onClick={handleAddPage}>Adicionar</Button>
//                     <Button color="secondary" onClick={toggleAddPageModal}>Cancelar</Button>
//                 </ModalFooter>
//             </Modal> */}

//             <Modal isOpen={addPageModalOpen} toggle={toggleAddPageModal} style={{ maxWidth: '100vw', height: '100vh', margin: 0 }}>
//                 <ModalHeader toggle={toggleAddPageModal}>Adicionar Página</ModalHeader>
//                 <ModalBody style={{ height: 'calc(100vh - 150px)' }}>

//                     <>
//                         <FormGroup>
//                             <Label for="page-title">Título da Página</Label>
//                             <Input
//                                 type="text"
//                                 id="page-title"
//                                 value={currentPageTitle}
//                                 onChange={e => setCurrentPageTitle(e.target.value)}
//                             />
//                         </FormGroup>
//                         <FormGroup>
//                             <Label for="page-content">Conteúdo da Página</Label>
//                             <Input
//                                 type="textarea"
//                                 id="page-content"
//                                 style={{ height: '90%' }}
//                                 value={currentPageContent}
//                                 rows={17}
//                                 onChange={e => setCurrentPageContent(e.target.value)}
//                             />
//                         </FormGroup>
//                         <FormGroup>
//                             <Label for="page-image">Imagem da Página</Label>
//                             <Input
//                                 type="file"
//                                 id="page-image"
//                                 accept="image/*"
//                                 onChange={e => {
//                                     if (e.target.files && e.target.files[0]) {
//                                         const file = e.target.files[0];
//                                         const reader = new FileReader();
//                                         reader.onload = (readEvent) => {
//                                             if (readEvent.target && readEvent.target.result) {
//                                                 setImagePageContent(readEvent.target.result as string);
//                                             }
//                                         };
//                                         reader.readAsDataURL(file);
//                                     }
//                                 }}
//                             />
//                             {/* {imagePageContent && (
//                                 <img src={imagePageContent} alt="Preview" style={{ width: '100%', marginTop: '10px' }} />
//                             )} */}
//                         </FormGroup>
//                     </>
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button color="primary" style={{ backgroundColor: "#1c547c" }} onClick={handleAddPage}>Adicionar</Button>
//                     <Button color="secondary" onClick={toggleAddPageModal}>Cancelar</Button>
//                 </ModalFooter>
//             </Modal>

//             <Modal isOpen={editPageModalOpen} toggle={toggleEditPageModal} style={{ maxWidth: '100vw', height: '100vh', margin: 0 }}>
//                 <ModalHeader toggle={toggleEditPageModal}>Editar Página</ModalHeader>
//                 <ModalBody style={{ height: 'calc(100vh - 150px)' }}>
//                     <FormGroup>
//                         <Label for="page-title">Título da Página</Label>
//                         <Input
//                             type="text"
//                             id="page-title"
//                             className="form-control"
//                             value={currentPageTitle}
//                             onChange={(e) => setCurrentPageTitle(e.target.value)}
//                         />
//                     </FormGroup>
//                     <FormGroup>
//                         <Label for="page-content">Conteúdo da Página</Label>
//                         <Input
//                             type="textarea"
//                             id="page-content"
//                             className="form-control"
//                             style={{ height: '90%' }}
//                             value={currentPageContent}
//                             rows={17}
//                             onChange={(e) => setCurrentPageContent(e.target.value)}
//                         />
//                     </FormGroup>
//                     {/* <FormGroup>
//                         <Label for="page-image">Imagem da Página</Label>
//                         <Input
//                             type="file"
//                             id="page-image"
//                             accept="image/*"
//                             value={imagePageContent}
//                             onChange={e => {
//                                 if (e.target.files && e.target.files[0]) {
//                                     const file = e.target.files[0];
//                                     const reader = new FileReader();
//                                     reader.onload = (readEvent) => {
//                                         if (readEvent.target && readEvent.target.result) {
//                                             setImagePageContent(readEvent.target.result as string);
//                                         }
//                                     };
//                                     reader.readAsDataURL(file);
//                                 }
//                             }}
//                         />
//                     </FormGroup> */}
//                     <FormGroup>
//                         <Label for="page-image">Imagem da Página</Label>
//                         <Input
//                             type="file"
//                             id="page-image"
//                             accept="image/*"
//                             onChange={e => {
//                                 if (e.target.files && e.target.files[0]) {
//                                     const file = e.target.files[0];
//                                     const reader = new FileReader();
//                                     reader.onload = (readEvent) => {
//                                         if (readEvent.target && readEvent.target.result) {
//                                             setImagePageContent(readEvent.target.result as string);
//                                         }
//                                     };
//                                     reader.readAsDataURL(file);
//                                 }
//                             }}
//                         />
//                     </FormGroup>
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button
//                         color="primary"
//                         style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                         onClick={handleEditPage}
//                     >
//                         Editar
//                     </Button>
//                     <Button color="secondary" onClick={toggleEditPageModal}>Cancelar</Button>
//                 </ModalFooter>
//             </Modal>

//             <Modal isOpen={pageModalOpen} toggle={() => togglePageModal(currentEmpresaId as number)}>
//                 <ModalHeader toggle={() => togglePageModal(currentEmpresaId as number)}>Selecione as Páginas</ModalHeader>
//                 <ModalBody>
//                     {pageNames.map((pageName, index) => (
//                         <div
//                             key={index}

//                             className="page-item p-2 mb-2 bg-light rounded d-flex align-items-center justify-content-between"
//                         >
//                             <FormGroup check>
//                                 <Label check>
//                                     <Input
//                                         type="checkbox"
//                                         checked={currentEmpresaId !== null && generatePages[currentEmpresaId]?.includes(index + 1)}
//                                         value={index + 1}
//                                         onChange={() => handleCheckboxChange(index + 1)}
//                                     />{' '}
//                                     Página {index + 1}: {pageName}
//                                 </Label>
//                             </FormGroup>
//                             <span className="drag-handle">&#8942;</span>
//                         </div>
//                     ))}
//                     {additionalPages[currentEmpresaId]?.map((page, index) => (
//                         <div
//                             key={page.index}

//                             className="page-item p-2 mb-2 bg-light rounded d-flex align-items-center justify-content-between"
//                         >
//                             <FormGroup check>
//                                 <Label check>
//                                     <Input
//                                         type="checkbox"
//                                         checked={currentEmpresaId !== null && generatePages[currentEmpresaId]?.includes(page.index)}
//                                         value={page.index}
//                                         onChange={() => handleCheckboxChange(page.index)}
//                                     />{' '}
//                                     Página adicional: {page.title}
//                                 </Label>
//                             </FormGroup>
//                             <span className="drag-handle">&#8942;</span>
//                         </div>
//                     ))}
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button
//                         color="primary"
//                         style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                         onClick={() => setPageModalOpen(false)}
//                     >
//                         Confirmar
//                     </Button>
//                     <Button color="secondary" onClick={() => setPageModalOpen(false)}>Cancelar</Button>
//                 </ModalFooter>
//             </Modal>
//         </>
//     );
// };

// export default Consolidation;

// ------------------------------------------------------------------------------------------------------------

// import {
//     Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
//     FormGroup, Label, Input
// } from "reactstrap";
// import { useContext, useEffect, useState } from "react";
// import Header from "../../components/Headers/Header";
// import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
// import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
// import { EmpresasContext } from "../../context/EmpresasContext";
// import { PresentationContext } from "../../context/PresentationContext";
// import { PresentationType, AdditionalPages } from "../../context/hooks/usePresentation";
// import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
// import ModalPresentation from "../../components/ModalPresentation/ModalPresentation";

// const Consolidation: React.FC = () => {
//     const [loading, setLoading] = useState<boolean>(false);
//     const { generatePdf } = useContext(PresentationContext);
//     const { listEmpresas, getAllNetworks, getEmpresasByNetwokr, listNetworks, setListEmpresas } = useContext(EmpresasContext);
//     const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);
//     const [selectedMonth, setSelectedMonth] = useState<string>('');
//     const [hasFinalConsolidation, setHasFinalConsolidation] = useState<boolean>(false);
//     const [hasFinalConsolidationConveniencia, setHasFinalConsolidationConveniencia] = useState<boolean>(false);
//     const [hasFinalConsolidationConvenienciaPosto, setHasFinalConsolidationConvenienciaPosto] = useState<boolean>(false);
//     const [addPageModalOpen, setAddPageModalOpen] = useState<boolean>(false);
//     const [editPageModalOpen, setEditPageModalOpen] = useState<boolean>(false);
//     const [currentPageTitle, setCurrentPageTitle] = useState<string>('');
//     const [currentPageContent, setCurrentPageContent] = useState<string>('');
//     const [imagePageContent, setImagePageContent] = useState<string | ''>('');

//     const [additionalPages, setAdditionalPages] = useState<{
//         [key: number]: AdditionalPages[];
//     }>(() => JSON.parse(localStorage.getItem("additionalPages") || "{}"));

//     const [currentEmpresaId, setCurrentEmpresaId] = useState<number>(0);
//     const [editPageIndex, setEditPageIndex] = useState<number | null>(null);
//     const [pageModalOpen, setPageModalOpen] = useState<boolean>(false);
    
//     const [generatePages, setGeneratePages] = useState<{
//         [key: number]: number[];
//     }>(() => JSON.parse(localStorage.getItem("generatePages") || "{}"));

//     const [selectedPages, setSelectedPages] = useState<{ [key: number]: boolean[] }>({});
//     const [conveniencePages, setConveniencePages] = useState<{ [key: number]: boolean }>({});
//     const [openPresentation, setOpenPresentation] = useState(false);

//     const pageNames: string[] = [
//         "Capa",
//         "Período",
//         "Vendas de Combustível em Quantidade",
//         "Vendas de Combustível em Valor",
//         "Percentual Margem Combustível",
//         "% de Vendas de Combustível em Quantidade",
//         "Venda de Produtos em Quantidade",
//         "Produtos sem Venda",
//         "Produtos em Estoque Pista",
//         "Percentual Margem Produtos Pista",
//         "Vendas Produtos com Margem Negativa Pista",
//         "Variação LMC",
//         "Evoluções Despesas",
//         "Percentual de Representatividade das Despesas",
//         "Evoluções Receitas Não Operacionais",
//         "Demonstração do Resultado do Mês",
//         "Demonstração do Resultado",
//         "Relatório Posição de Liquidez",
//         "Fluxo De Caixa"
//     ];

//     useEffect(() => {
//         localStorage.setItem('additionalPages', JSON.stringify(additionalPages));
//         localStorage.setItem('generatePages', JSON.stringify(generatePages));
//         localStorage.setItem('selectedNetwork', selectedNetwork || "");
//     }, [additionalPages, listEmpresas, selectedMonth, selectedNetwork, generatePages]);

//     useEffect(() => {
//         getAllNetworks();
//         if (listEmpresas.length > 0) {
//             const initialGeneratePages: { [key: number]: number[] } = {};
//             const initialSelectedPages: { [key: number]: boolean[] } = {};
//             const initialConveniencePages: { [key: number]: boolean } = {};
//             listEmpresas.forEach(empresa => {
//                 initialGeneratePages[empresa.id] = Array.from({ length: 19 }, (_, i) => i + 1);
//                 initialSelectedPages[empresa.id] = Array.from({ length: 19 }, () => true);
//                 initialConveniencePages[empresa.id] = empresa.hasConvenience || false;
//             });
            
//             if (localStorage.getItem('generatePages') === "{}") {
//                 setGeneratePages(initialGeneratePages);
//             }
//             setSelectedPages(initialSelectedPages);
//             setConveniencePages(initialConveniencePages);
//         }
//     }, [listEmpresas]);

//     const handleNetworkChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
//         const network = event.target.value;
//         setSelectedNetwork(network);
//         setLoading(true);
//         await getEmpresasByNetwokr(network);
//         setLoading(false);
//     };

//     const handleGeneratePdf = async () => {
//         setLoading(true);
//         if (selectedNetwork && selectedMonth) {
//             const empresas: PresentationType[] = listEmpresas.map(empresa => ({
//                 id: empresa.id,
//                 name: empresa.name,
//                 generatePages: generatePages[empresa.id]?.filter((page, index) => selectedPages[empresa.id][index]),
//                 additionalPages: additionalPages[empresa.id] || [],
//                 hasConvenience: conveniencePages[empresa.id] || false,
//             }));

//             try {
//                 await generatePdf(empresas, selectedMonth, hasFinalConsolidation, hasFinalConsolidationConveniencia, hasFinalConsolidationConvenienciaPosto);
//             } catch (error) {
//                 console.error('Erro ao gerar PDF:', error);
//             } finally {
//                 setLoading(false);
//             }
//         }
//     };


//     const toggleAddPageModal = () => {
//         setAddPageModalOpen(!addPageModalOpen);
//         setCurrentPageTitle('');
//         setCurrentPageContent('');
//         setImagePageContent('');
//     };

//     const toggleEditPageModal = () => {
//         setEditPageModalOpen(!editPageModalOpen);
//         setCurrentPageTitle('');
//         setCurrentPageContent('');
//         setImagePageContent('');
//         setEditPageIndex(null);
//     };

//     const openEditModalForEmpresa = (empresaId: number, pageIndex: number) => {
//         setCurrentEmpresaId(empresaId);
//         const page = additionalPages[empresaId][pageIndex];
//         setCurrentPageTitle(page.title);
//         setCurrentPageContent(page.content ? page.content : '');
//         setImagePageContent(page.imagem ? page.imagem : '');
//         setEditPageIndex(pageIndex);
//         setEditPageModalOpen(true);
//     };

//     const openAddModalForEmpresa = (empresaId: number) => {
//         setCurrentEmpresaId(empresaId);
//         setAddPageModalOpen(true);
//     };

//     const handleAddPage = () => {
//         if (currentPageTitle && currentEmpresaId !== null) {
//             const newPage: AdditionalPages = {
//                 index: 20 + (additionalPages[currentEmpresaId]?.length || 0),
//                 title: currentPageTitle,
//                 content: currentPageContent ? currentPageContent : '',
//                 imagem: imagePageContent ? imagePageContent : ''
//             };
//             setAdditionalPages(prev => ({
//                 ...prev,
//                 [currentEmpresaId]: [...(prev[currentEmpresaId] || []), newPage],
//             }));
//             setGeneratePages(prev => ({
//                 ...prev,
//                 [currentEmpresaId]: [...(prev[currentEmpresaId] || Array.from({ length: 19 }, (_, i) => i + 1)), 20 + (prev[currentEmpresaId]?.filter(p => p >= 20).length || 0)]
//             }));
//             setSelectedPages(prev => ({
//                 ...prev,
//                 [currentEmpresaId]: [...(prev[currentEmpresaId] || Array.from({ length: 19 }, () => true)), true]
//             }));
//             toggleAddPageModal();
//         }
//     };

//     const handleEditPage = () => {
//         if (currentPageTitle && currentEmpresaId !== null && editPageIndex !== null) {
//             const updatedPages = [...additionalPages[currentEmpresaId]];
//             updatedPages[editPageIndex] = {
//                 index: updatedPages[editPageIndex].index,
//                 title: currentPageTitle,
//                 content: currentPageContent ? currentPageContent : '',
//                 imagem: imagePageContent ? imagePageContent : ''
//             };
//             setAdditionalPages(prev => ({
//                 ...prev,
//                 [currentEmpresaId]: updatedPages,
//             }));
//             toggleEditPageModal();
//         }
//     };

//     const removePage = (empresaId: number, pageIndex: number) => {
//         const updatedPages = additionalPages[empresaId].filter((page, index) => index !== pageIndex);
//         setAdditionalPages(prev => ({
//             ...prev,
//             [empresaId]: updatedPages,
//         }));
//         setGeneratePages(prev => ({
//             ...prev,
//             [empresaId]: prev[empresaId].filter(p => p !== 20 + pageIndex)
//         }));
//         setSelectedPages(prev => ({
//             ...prev,
//             [empresaId]: prev[empresaId].filter((_, index) => index !== pageIndex)
//         }));
//     };

//     const removeEmpresa = (id: number) => {
//         const newEmpresas = listEmpresas.filter(empresa => empresa.id !== id);
//         setListEmpresas(newEmpresas);
//     };

//     const moveUp = (index: number) => {
//         if (index > 0) {
//             const newEmpresas = [...listEmpresas];
//             const [movedItem] = newEmpresas.splice(index, 1);
//             newEmpresas.splice(index - 1, 0, movedItem);
//             setListEmpresas(newEmpresas);
//         }
//     };

//     const moveDown = (index: number) => {
//         if (index < listEmpresas.length - 1) {
//             const newEmpresas = [...listEmpresas];
//             const [movedItem] = newEmpresas.splice(index, 1);
//             newEmpresas.splice(index + 1, 0, movedItem);
//             setListEmpresas(newEmpresas);
//         }
//     };

//     const togglePageModal = (empresaId: number) => {
//         setCurrentEmpresaId(empresaId);
//         setPageModalOpen(!pageModalOpen);
//     };

//     const handleCheckboxChange = (pageIndex: number) => {
//         if (currentEmpresaId !== null) {
//             setSelectedPages(prevSelectedPages => {
//                 const updatedSelected = [...(prevSelectedPages[currentEmpresaId] || [])];
//                 updatedSelected[pageIndex] = !updatedSelected[pageIndex];  

//                 return {
//                     ...prevSelectedPages,
//                     [currentEmpresaId]: updatedSelected,
//                 };
//             });
//         }
//     };


//     const onDragEnd = (result: DropResult) => {
//         if (!result.destination) return;

//         const sourceIndex = result.source.index;
//         const destinationIndex = result.destination.index;

//         setGeneratePages(prevGeneratePages => {
//             const updatedPages = [...(prevGeneratePages[currentEmpresaId] || [])];
//             const [movedPage] = updatedPages.splice(sourceIndex, 1);
//             updatedPages.splice(destinationIndex, 0, movedPage);

//             return {
//                 ...prevGeneratePages,
//                 [currentEmpresaId]: updatedPages,
//             };
//         });

//         setSelectedPages(prevSelectedPages => {
//             const updatedSelected = [...(prevSelectedPages[currentEmpresaId] || [])];
//             const [movedSelected] = updatedSelected.splice(sourceIndex, 1);
//             updatedSelected.splice(destinationIndex, 0, movedSelected);

//             return {
//                 ...prevSelectedPages,
//                 [currentEmpresaId]: updatedSelected,
//             };
//         });
//     };

//     const handleDragEnd = (result: DropResult) => {
//         const { destination, source, draggableId } = result;

//         if (!destination) return;

//         if (destination.droppableId === source.droppableId && destination.index === source.index) {
//             return;
//         }

//         if (source.droppableId === "empresas") {
//             const newEmpresas = Array.from(listEmpresas);
//             const [reorderedItem] = newEmpresas.splice(source.index, 1);
//             newEmpresas.splice(destination.index, 0, reorderedItem);

//             setListEmpresas(newEmpresas);
//         } else if (source.droppableId === "page-list" && currentEmpresaId !== null) {
//             const newPages = Array.from(generatePages[currentEmpresaId]);
//             const [reorderedPage] = newPages.splice(source.index, 1);
//             newPages.splice(destination.index, 0, reorderedPage);

//             setGeneratePages(prev => ({
//                 ...prev,
//                 [currentEmpresaId]: newPages
//             }));
//         }
//     };

//     const handleConvenienceChange = (empresaId: number) => {
//         setConveniencePages(prev => ({
//             ...prev,
//             [empresaId]: !prev[empresaId],
//         }));
//     };

//     return (
//         <>
//             <LoadingSpinner />
//             <Header />
//             <AdminNavbar brandText={"Consolidação"} search={false} titleSearch={""} />
//             <Container className="mt-4">
//                 <Row>
//                     <Col md="6">
//                         <div className="mb-3">
//                             <label htmlFor="network-select" className="form-label">Selecione uma Rede:</label>
//                             <select
//                                 id="network-select"
//                                 className="form-control form-control-alternative"
//                                 onChange={handleNetworkChange}
//                                 value={selectedNetwork || ''}
//                             >
//                                 <option value="">Selecione uma rede</option>
//                                 {listNetworks.map((network) => (
//                                     <option key={network.name} value={network.name}>
//                                         {network.name}
//                                     </option>
//                                 ))}
//                             </select>
//                         </div>
//                         <div className="mt-3">
//                             <label htmlFor="month-select" className="form-label">Selecione até qual mês deseja o relatório:</label>
//                             <select
//                                 id="month-select"
//                                 className="form-control form-control-alternative"
//                                 onChange={(e) => setSelectedMonth(e.target.value)}
//                                 value={selectedMonth}
//                             >
//                                 <option value="">Selecione um mês</option>
//                                 <option value="Janeiro">Janeiro</option>
//                                 <option value="Fevereiro">Fevereiro</option>
//                                 <option value="Março">Março</option>
//                                 <option value="Abril">Abril</option>
//                                 <option value="Maio">Maio</option>
//                                 <option value="Junho">Junho</option>
//                                 <option value="Julho">Julho</option>
//                                 <option value="Agosto">Agosto</option>
//                                 <option value="Setembro">Setembro</option>
//                                 <option value="Outubro">Outubro</option>
//                                 <option value="Novembro">Novembro</option>
//                                 <option value="Dezembro">Dezembro</option>
//                             </select>
//                         </div>
//                         <FormGroup check className="mt-3">
//                             <Label check>
//                                 <Input
//                                     type="checkbox"
//                                     checked={hasFinalConsolidation}
//                                     onChange={() => setHasFinalConsolidation(!hasFinalConsolidation)}
//                                 />{' '}
//                                 Incluir páginas de consolidação final do posto
//                             </Label>
//                             <Label check>
//                                 <Input
//                                     type="checkbox"
//                                     checked={hasFinalConsolidationConveniencia}
//                                     onChange={() => setHasFinalConsolidationConveniencia(!hasFinalConsolidationConveniencia)}
//                                 />{' '}
//                                 Incluir páginas de consolidação final da conveniência
//                             </Label>
//                             <Label check>
//                                 <Input
//                                     type="checkbox"
//                                     checked={hasFinalConsolidationConvenienciaPosto}
//                                     onChange={() => setHasFinalConsolidationConvenienciaPosto(!hasFinalConsolidationConvenienciaPosto)}
//                                 />{' '}
//                                 Incluir páginas de consolidação final do posto e da conveniência
//                             </Label>
//                         </FormGroup>
//                     </Col>
//                     {selectedNetwork && selectedMonth && (
//                         <Col md="6" className="d-flex flex-column">
//                             <div className="w-100 mb-3">
//                                 <div
//                                     className="empresas-list p-3 rounded"
//                                     style={{ backgroundColor: "#1c547c" }}
//                                 >
//                                     <h3 className="mb-3" style={{ color: "#FFF" }}>Empresas na Rede {selectedNetwork}</h3>
//                                     <DragDropContext onDragEnd={handleDragEnd}>
//                                         <Droppable droppableId="empresas">
//                                             {(provided, snapshot) => (
//                                                 <div
//                                                     ref={provided.innerRef}
//                                                     {...provided.droppableProps}
//                                                 >
//                                                     {listEmpresas.map((empresa, index) => (
//                                                         <Draggable key={empresa.id} draggableId={empresa.id.toString()} index={index}>
//                                                             {(provided, snapshot) => (
//                                                                 <div
//                                                                     ref={provided.innerRef}
//                                                                     {...provided.draggableProps}
//                                                                     {...provided.dragHandleProps}
//                                                                     className="empresa-item bg-white rounded mb-2 p-3 d-flex flex-column"
//                                                                 >
//                                                                     <div className="d-flex align-items-center mb-2">
//                                                                         <span className="drag-handle mr-2">&#8942;</span>
//                                                                         <span className="empresa-name flex-grow-1 ms-3">{empresa.name}</span>
//                                                                         <button
//                                                                             className="btn btn-primary btn-sm ms-2"
//                                                                             style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                                                             onClick={() => togglePageModal(empresa.id)}
//                                                                         >
//                                                                             <i className="fas fa-file-alt"></i>
//                                                                         </button>
//                                                                         <button
//                                                                             className="btn btn-primary btn-sm ms-2"
//                                                                             style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                                                             onClick={() => openAddModalForEmpresa(empresa.id)}
//                                                                         >
//                                                                             <i className="fas fa-plus"></i>
//                                                                         </button>
//                                                                         <button
//                                                                             className="btn btn-outline-danger btn-sm me-2"
//                                                                             onClick={() => removeEmpresa(empresa.id)}
//                                                                         >
//                                                                             <i className="fas fa-trash-alt"></i>
//                                                                         </button>
//                                                                         <div className="btn-group">
//                                                                             <button
//                                                                                 className="btn btn-outline-secondary btn-sm"
//                                                                                 onClick={() => moveUp(index)}
//                                                                             >
//                                                                                 <i className="fas fa-arrow-up"></i>
//                                                                             </button>
//                                                                             <button
//                                                                                 className="btn btn-outline-secondary btn-sm"
//                                                                                 onClick={() => moveDown(index)}
//                                                                             >
//                                                                                 <i className="fas fa-arrow-down"></i>
//                                                                             </button>
//                                                                         </div>
//                                                                     </div>
//                                                                     {empresa.hasConvenience && (
//                                                                         <FormGroup check style={{ fontSize: '12px', color: 'grey', textAlign: 'right' }}>
//                                                                             <Label check>
//                                                                                 <Input
//                                                                                     type="checkbox"
//                                                                                     checked={conveniencePages[empresa.id] || false}
//                                                                                     onChange={() => handleConvenienceChange(empresa.id)}
//                                                                                 />{' '}
//                                                                                 Habilitar páginas de conveniência
//                                                                             </Label>
//                                                                         </FormGroup>
//                                                                     )}
//                                                                     <div className="additional-pages mt-3">
//                                                                         {additionalPages[empresa.id]?.map((page, pageIndex) => (
//                                                                             <div key={pageIndex} className="additional-page mb-2 p-3 bg-light rounded">
//                                                                                 <h5>{page.title}</h5>
//                                                                                 <p>
//                                                                                     {page.content ? page.content : page.imagem ? "Imagem" : ""}
//                                                                                 </p>
//                                                                                 <div>
//                                                                                     <button
//                                                                                         className="btn btn-primary btn-sm me-2"
//                                                                                         style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                                                                         onClick={() => openEditModalForEmpresa(empresa.id, pageIndex)}
//                                                                                     >
//                                                                                         <i className="fas fa-edit"></i>
//                                                                                     </button>
//                                                                                     <button
//                                                                                         className="btn btn-outline-danger btn-sm"
//                                                                                         onClick={() => removePage(empresa.id, pageIndex)}
//                                                                                     >
//                                                                                         <i className="fas fa-trash-alt"></i>
//                                                                                     </button>
//                                                                                 </div>
//                                                                             </div>
//                                                                         ))}
//                                                                     </div>
//                                                                 </div>
//                                                             )}
//                                                         </Draggable>
//                                                     ))}
//                                                     {provided.placeholder}
//                                                 </div>
//                                             )}
//                                         </Droppable>
//                                     </DragDropContext>
//                                 </div>
//                             </div>
//                             <Button
//                                 style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                 size="m"
//                                 onClick={handleGeneratePdf}
//                                 className="mt-3"
//                             >
//                                 Consolidar relatórios
//                             </Button>
//                             <Button
//                                 style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                                 size="m"
//                                 onClick={() => setOpenPresentation(true)}
//                                 className="mt-3"
//                             >
//                                 Apresentação slides
//                             </Button>
//                         </Col>
//                     )}
//                 </Row>
//             </Container>

//             <Modal isOpen={addPageModalOpen} toggle={toggleAddPageModal} style={{ maxWidth: '100vw', height: '100vh', margin: 0 }}>
//                 <ModalHeader toggle={toggleAddPageModal}>Adicionar Página</ModalHeader>
//                 <ModalBody style={{ height: 'calc(100vh - 150px)' }}>
//                     <FormGroup>
//                         <Label for="page-title">Título da Página</Label>
//                         <Input
//                             type="text"
//                             id="page-title"
//                             value={currentPageTitle}
//                             onChange={e => setCurrentPageTitle(e.target.value)}
//                         />
//                     </FormGroup>
//                     <FormGroup>
//                         <Label for="page-content">Conteúdo da Página</Label>
//                         <Input
//                             type="textarea"
//                             id="page-content"
//                             style={{ height: '90%' }}
//                             value={currentPageContent}
//                             rows={17}
//                             onChange={e => setCurrentPageContent(e.target.value)}
//                         />
//                     </FormGroup>
//                     <FormGroup>
//                         <Label for="page-image">Imagem da Página</Label>
//                         <Input
//                             type="file"
//                             id="page-image"
//                             accept="image/*"
//                             onChange={e => {
//                                 if (e.target.files && e.target.files[0]) {
//                                     const file = e.target.files[0];
//                                     const reader = new FileReader();
//                                     reader.onload = (readEvent) => {
//                                         if (readEvent.target && readEvent.target.result) {
//                                             setImagePageContent(readEvent.target.result as string);
//                                         }
//                                     };
//                                     reader.readAsDataURL(file);
//                                 }
//                             }}
//                         />
//                     </FormGroup>
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button color="primary" style={{ backgroundColor: "#1c547c" }} onClick={handleAddPage}>Adicionar</Button>
//                     <Button color="secondary" onClick={toggleAddPageModal}>Cancelar</Button>
//                 </ModalFooter>
//             </Modal>

//             <Modal isOpen={editPageModalOpen} toggle={toggleEditPageModal} style={{ maxWidth: '100vw', height: '100vh', margin: 0 }}>
//                 <ModalHeader toggle={toggleEditPageModal}>Editar Página</ModalHeader>
//                 <ModalBody style={{ height: 'calc(100vh - 150px)' }}>
//                     <FormGroup>
//                         <Label for="page-title">Título da Página</Label>
//                         <Input
//                             type="text"
//                             id="page-title"
//                             className="form-control"
//                             value={currentPageTitle}
//                             onChange={(e) => setCurrentPageTitle(e.target.value)}
//                         />
//                     </FormGroup>
//                     <FormGroup>
//                         <Label for="page-content">Conteúdo da Página</Label>
//                         <Input
//                             type="textarea"
//                             id="page-content"
//                             className="form-control"
//                             style={{ height: '90%' }}
//                             value={currentPageContent}
//                             rows={17}
//                             onChange={(e) => setCurrentPageContent(e.target.value)}
//                         />
//                     </FormGroup>
//                     <FormGroup>
//                         <Label for="page-image">Imagem da Página</Label>
//                         <Input
//                             type="file"
//                             id="page-image"
//                             accept="image/*"
//                             onChange={e => {
//                                 if (e.target.files && e.target.files[0]) {
//                                     const file = e.target.files[0];
//                                     const reader = new FileReader();
//                                     reader.onload = (readEvent) => {
//                                         if (readEvent.target && readEvent.target.result) {
//                                             setImagePageContent(readEvent.target.result as string);
//                                         }
//                                     };
//                                     reader.readAsDataURL(file);
//                                 }
//                             }}
//                         />
//                     </FormGroup>
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button
//                         color="primary"
//                         style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                         onClick={handleEditPage}
//                     >
//                         Editar
//                     </Button>
//                     <Button color="secondary" onClick={toggleEditPageModal}>Cancelar</Button>
//                 </ModalFooter>
//             </Modal>

//             <Modal isOpen={pageModalOpen} toggle={() => togglePageModal(currentEmpresaId)}>
//                 <ModalHeader toggle={() => togglePageModal(currentEmpresaId)}>Selecione as Páginas</ModalHeader>
//                 <ModalBody>
//                     <DragDropContext onDragEnd={onDragEnd}>
//                         <Droppable droppableId="page-list">
//                             {(provided) => (
//                                 <div
//                                     {...provided.droppableProps}
//                                     ref={provided.innerRef}
//                                 >
//                                     {generatePages[currentEmpresaId]?.map((page, index) => (
//                                         <Draggable key={page} draggableId={`${page}`} index={index}>
//                                             {(provided) => (
//                                                 <div
//                                                     ref={provided.innerRef}
//                                                     {...provided.draggableProps}
//                                                     {...provided.dragHandleProps}
//                                                     className="page-item p-2 mb-2 bg-light rounded d-flex align-items-center justify-content-between"
//                                                 >
//                                                     <FormGroup check>
//                                                         <Label check>
//                                                             <Input
//                                                                 type="checkbox"
//                                                                 checked={selectedPages[currentEmpresaId]?.[index] ?? true}
//                                                                 onChange={() => handleCheckboxChange(index)}
//                                                             />{' '}
//                                                             Página {page}: {page <= 19 ? pageNames[page - 1] : `Adicional ${page - 19}`}
//                                                         </Label>
//                                                     </FormGroup>
//                                                     <span className="drag-handle">&#8942;</span>
//                                                 </div>
//                                             )}
//                                         </Draggable>
//                                     ))}
//                                     {provided.placeholder}
//                                 </div>
//                             )}
//                         </Droppable>
//                     </DragDropContext>
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button
//                         color="primary"
//                         style={{ color: "#FFF", backgroundColor: "#1c547c" }}
//                         onClick={() => setPageModalOpen(false)}
//                     >
//                         Confirmar
//                     </Button>
//                     <Button color="secondary" onClick={() => setPageModalOpen(false)}>Cancelar</Button>
//                 </ModalFooter>
//             </Modal>
//             <ModalPresentation
//                 empresas={listEmpresas.map((empresa) => ({
//                     id: empresa.id,
//                     name: empresa.name,
//                     // generatePages: generatePages[empresa.id]?.filter((page, index) => selectedPages[empresa.id] && selectedPages[empresa.id][index]),
//                     generatePages: generatePages[empresa.id] || Array.from({ length: 19 }, (_, i) => i + 1),
//                     additionalPages: additionalPages[empresa.id] || [],
//                     hasConvenience: conveniencePages[empresa.id] || false,
//                 }))}
//                 hasFinalConsolidation={hasFinalConsolidation}
//                 hasFinalConsolidationConveniencia={hasFinalConsolidationConveniencia}
//                 hasFinalConsolidationConvenienciaPosto={hasFinalConsolidationConvenienciaPosto}
//                 selectedMonth={selectedMonth}
//                 open={openPresentation}
//                 handleClose={() => setOpenPresentation(false)}
//             />
//         </>
//     );
// };

// export default Consolidation;

// ------------------------------------------------------------------------------------------------------------

import {
    Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,
    FormGroup, Label, Input
} from "reactstrap";
import { useContext, useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { EmpresasContext } from "../../context/EmpresasContext";
import { PresentationContext } from "../../context/PresentationContext";
import { PresentationType, AdditionalPages } from "../../context/hooks/usePresentation";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import ModalPresentation from "../../components/ModalPresentation/ModalPresentation";

const Consolidation: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { generatePdf } = useContext(PresentationContext);
    const { listEmpresas, getAllNetworks, getEmpresasByNetwokr, listNetworks, setListEmpresas } = useContext(EmpresasContext);
    const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);
    const [selectedMonth, setSelectedMonth] = useState<string>('');
    const [hasFinalConsolidation, setHasFinalConsolidation] = useState<boolean>(false);
    const [hasFinalConsolidationConveniencia, setHasFinalConsolidationConveniencia] = useState<boolean>(false);
    const [hasFinalConsolidationConvenienciaPosto, setHasFinalConsolidationConvenienciaPosto] = useState<boolean>(false);
    const [addPageModalOpen, setAddPageModalOpen] = useState<boolean>(false);
    const [editPageModalOpen, setEditPageModalOpen] = useState<boolean>(false);
    const [currentPageTitle, setCurrentPageTitle] = useState<string>('');
    const [currentPageContent, setCurrentPageContent] = useState<string>('');
    const [imagePageContent, setImagePageContent] = useState<string | ''>('');

    const [additionalPages, setAdditionalPages] = useState<{
        [key: number]: AdditionalPages[];
    }>(() => JSON.parse(localStorage.getItem("additionalPages") || "{}"));

    const [currentEmpresaId, setCurrentEmpresaId] = useState<number>(0);
    const [editPageIndex, setEditPageIndex] = useState<number | null>(null);
    const [pageModalOpen, setPageModalOpen] = useState<boolean>(false);
    
    const [generatePages, setGeneratePages] = useState<{
        [key: number]: number[];
    }>(() => JSON.parse(localStorage.getItem("generatePages") || "{}"));

    const [selectedPages, setSelectedPages] = useState<{ [key: number]: boolean[] }>({});
    const [conveniencePages, setConveniencePages] = useState<{ [key: number]: boolean }>({});
    const [openPresentation, setOpenPresentation] = useState(false);

    const pageNames: string[] = [
        "Capa",
        "Período",
        "Vendas de Combustível em Quantidade",
        "Vendas de Combustível em Valor",
        "Percentual Margem Combustível",
        "% de Vendas de Combustível em Quantidade",
        "Venda de Produtos em Quantidade",
        "Produtos sem Venda",
        "Produtos em Estoque Pista",
        "Percentual Margem Produtos Pista",
        "Vendas Produtos com Margem Negativa Pista",
        "Variação LMC",
        "Evoluções Despesas",
        "Percentual de Representatividade das Despesas",
        "Evoluções Receitas Não Operacionais",
        "Demonstração do Resultado do Mês",
        "Demonstração do Resultado",
        "Relatório Posição de Liquidez",
        "Fluxo De Caixa"
    ];

    useEffect(() => {
        localStorage.setItem('additionalPages', JSON.stringify(additionalPages));
        localStorage.setItem('generatePages', JSON.stringify(generatePages));
        localStorage.setItem('selectedNetwork', selectedNetwork || "");
    }, [additionalPages, listEmpresas, selectedMonth, selectedNetwork, generatePages]);

    // useEffect(() => {
    //     getAllNetworks();
    //     if (listEmpresas.length > 0) {
    //         const initialGeneratePages: { [key: number]: number[] } = {};
    //         const initialSelectedPages: { [key: number]: boolean[] } = {};
    //         const initialConveniencePages: { [key: number]: boolean } = {};
    //         listEmpresas.forEach(empresa => {
    //             initialGeneratePages[empresa.id] = Array.from({ length: 19 }, (_, i) => i + 1);
    //             initialSelectedPages[empresa.id] = Array.from({ length: 19 }, () => true);
    //             initialConveniencePages[empresa.id] = empresa.hasConvenience || false;
    //         });
            
    //         if (localStorage.getItem('generatePages') === "{}") {
    //             setGeneratePages(initialGeneratePages);
    //         }
    //         setSelectedPages(initialSelectedPages);
    //         setConveniencePages(initialConveniencePages);
    //     }
    // }, [listEmpresas]);

    useEffect(() => {
        getAllNetworks();
        if (listEmpresas.length > 0) {
            const initialGeneratePages: { [key: number]: number[] } = {};
            const initialSelectedPages: { [key: number]: boolean[] } = {};
            const initialConveniencePages: { [key: number]: boolean } = {};
    
            listEmpresas.forEach(empresa => {
                initialGeneratePages[empresa.id] = generatePages[empresa.id] || Array.from({ length: 19 }, (_, i) => i + 1);
                initialSelectedPages[empresa.id] = selectedPages[empresa.id] || Array.from({ length: 19 }, () => true);
                initialConveniencePages[empresa.id] = empresa.hasConvenience || false;
            });
    
            setGeneratePages(prev => ({
                ...prev,
                ...initialGeneratePages
            }));
    
            setSelectedPages(prev => ({
                ...prev,
                ...initialSelectedPages
            }));
    
            setConveniencePages(prev => ({
                ...prev,
                ...initialConveniencePages
            }));
        }
    }, [listEmpresas]);

    const handleNetworkChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        const network = event.target.value;
        setSelectedNetwork(network);
        setLoading(true);
        await getEmpresasByNetwokr(network);
        setLoading(false);
    };

    // const handleGeneratePdf = async () => {
    //     setLoading(true);
    //     if (selectedNetwork && selectedMonth) {
    //         const empresas: PresentationType[] = listEmpresas.map(empresa => ({
    //             id: empresa.id,
    //             name: empresa.name,
    //             generatePages: generatePages[empresa.id]?.filter((page, index) => selectedPages[empresa.id][index]),
    //             additionalPages: additionalPages[empresa.id] || [],
    //             hasConvenience: conveniencePages[empresa.id] || false,
    //         }));

    //         try {
    //             await generatePdf(empresas, selectedMonth, hasFinalConsolidation, hasFinalConsolidationConveniencia, hasFinalConsolidationConvenienciaPosto);
    //         } catch (error) {
    //             console.error('Erro ao gerar PDF:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     }
    // };

    const handleGeneratePdf = async () => {
        setLoading(true);
        if (selectedNetwork && selectedMonth) {
            const empresas: PresentationType[] = listEmpresas.map(empresa => {
                const pages = generatePages[empresa.id] || [];
                const filteredPages = pages.filter((page, index) => selectedPages[empresa.id]?.[index]);
    
                return {
                    id: empresa.id,
                    name: empresa.name,
                    generatePages: filteredPages.length > 0 ? filteredPages : Array.from({ length: 19 }, (_, i) => i + 1), // Fallback para todas as páginas padrão se filteredPages estiver vazio
                    additionalPages: additionalPages[empresa.id] || [],
                    hasConvenience: conveniencePages[empresa.id] || false,
                };
            });
    
            try {
                await generatePdf(empresas, selectedMonth, hasFinalConsolidation, hasFinalConsolidationConveniencia, hasFinalConsolidationConvenienciaPosto);
            } catch (error) {
                console.error('Erro ao gerar PDF:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const toggleAddPageModal = () => {
        setAddPageModalOpen(!addPageModalOpen);
        setCurrentPageTitle('');
        setCurrentPageContent('');
        setImagePageContent('');
    };

    const toggleEditPageModal = () => {
        setEditPageModalOpen(!editPageModalOpen);
        setCurrentPageTitle('');
        setCurrentPageContent('');
        setImagePageContent('');
        setEditPageIndex(null);
    };

    const openEditModalForEmpresa = (empresaId: number, pageIndex: number) => {
        setCurrentEmpresaId(empresaId);
        const page = additionalPages[empresaId][pageIndex];
        setCurrentPageTitle(page.title);
        setCurrentPageContent(page.content ? page.content : '');
        setImagePageContent(page.imagem ? page.imagem : '');
        setEditPageIndex(pageIndex);
        setEditPageModalOpen(true);
    };

    const openAddModalForEmpresa = (empresaId: number) => {
        setCurrentEmpresaId(empresaId);
        setAddPageModalOpen(true);
    };

    const handleAddPage = () => {
        if (currentPageTitle && currentEmpresaId !== null) {
            const newPage: AdditionalPages = {
                index: 20 + (additionalPages[currentEmpresaId]?.length || 0),
                title: currentPageTitle,
                content: currentPageContent ? currentPageContent : '',
                imagem: imagePageContent ? imagePageContent : ''
            };
            setAdditionalPages(prev => ({
                ...prev,
                [currentEmpresaId]: [...(prev[currentEmpresaId] || []), newPage],
            }));
            setGeneratePages(prev => ({
                ...prev,
                [currentEmpresaId]: [...(prev[currentEmpresaId] || Array.from({ length: 19 }, (_, i) => i + 1)), 20 + (prev[currentEmpresaId]?.filter(p => p >= 20).length || 0)]
            }));
            setSelectedPages(prev => ({
                ...prev,
                [currentEmpresaId]: [...(prev[currentEmpresaId] || Array.from({ length: 19 }, () => true)), true]
            }));
            toggleAddPageModal();
        }
    };

    const handleEditPage = () => {
        if (currentPageTitle && currentEmpresaId !== null && editPageIndex !== null) {
            const updatedPages = [...additionalPages[currentEmpresaId]];
            updatedPages[editPageIndex] = {
                index: updatedPages[editPageIndex].index,
                title: currentPageTitle,
                content: currentPageContent ? currentPageContent : '',
                imagem: imagePageContent ? imagePageContent : ''
            };
            setAdditionalPages(prev => ({
                ...prev,
                [currentEmpresaId]: updatedPages,
            }));
            toggleEditPageModal();
        }
    };

    const removePage = (empresaId: number, pageIndex: number) => {
        const updatedPages = additionalPages[empresaId].filter((_, index) => index !== pageIndex);
        setAdditionalPages(prev => ({
            ...prev,
            [empresaId]: updatedPages,
        }));
        setGeneratePages(prev => ({
            ...prev,
            [empresaId]: prev[empresaId].filter(p => p !== 20 + pageIndex)
        }));
        setSelectedPages(prev => ({
            ...prev,
            [empresaId]: prev[empresaId].filter((_, index) => index !== pageIndex)
        }));
    };

    const removeEmpresa = (id: number) => {
        const newEmpresas = listEmpresas.filter(empresa => empresa.id !== id);
        setListEmpresas(newEmpresas);
    };

    const moveUp = (index: number) => {
        if (index > 0) {
            const newEmpresas = [...listEmpresas];
            const [movedItem] = newEmpresas.splice(index, 1);
            newEmpresas.splice(index - 1, 0, movedItem);
            setListEmpresas(newEmpresas);
        }
    };

    const moveDown = (index: number) => {
        if (index < listEmpresas.length - 1) {
            const newEmpresas = [...listEmpresas];
            const [movedItem] = newEmpresas.splice(index, 1);
            newEmpresas.splice(index + 1, 0, movedItem);
            setListEmpresas(newEmpresas);
        }
    };

    const togglePageModal = (empresaId: number) => {
        setCurrentEmpresaId(empresaId);
        setPageModalOpen(!pageModalOpen);
    };

    const handleCheckboxChange = (pageIndex: number) => {
        if (currentEmpresaId !== null) {
            setSelectedPages(prevSelectedPages => {
                const updatedSelected = [...(prevSelectedPages[currentEmpresaId] || [])];
                updatedSelected[pageIndex] = !updatedSelected[pageIndex];  

                return {
                    ...prevSelectedPages,
                    [currentEmpresaId]: updatedSelected,
                };
            });
        }
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        setGeneratePages(prevGeneratePages => {
            const updatedPages = [...(prevGeneratePages[currentEmpresaId] || [])];
            const [movedPage] = updatedPages.splice(sourceIndex, 1);
            updatedPages.splice(destinationIndex, 0, movedPage);

            return {
                ...prevGeneratePages,
                [currentEmpresaId]: updatedPages,
            };
        });

        setSelectedPages(prevSelectedPages => {
            const updatedSelected = [...(prevSelectedPages[currentEmpresaId] || [])];
            const [movedSelected] = updatedSelected.splice(sourceIndex, 1);
            updatedSelected.splice(destinationIndex, 0, movedSelected);

            return {
                ...prevSelectedPages,
                [currentEmpresaId]: updatedSelected,
            };
        });
    };

    const handleDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result;

        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (source.droppableId === "empresas") {
            const newEmpresas = Array.from(listEmpresas);
            const [reorderedItem] = newEmpresas.splice(source.index, 1);
            newEmpresas.splice(destination.index, 0, reorderedItem);

            setListEmpresas(newEmpresas);
        } else if (source.droppableId === "page-list" && currentEmpresaId !== null) {
            const newPages = Array.from(generatePages[currentEmpresaId]);
            const [reorderedPage] = newPages.splice(source.index, 1);
            newPages.splice(destination.index, 0, reorderedPage);

            setGeneratePages(prev => ({
                ...prev,
                [currentEmpresaId]: newPages
            }));
        }
    };

    const handleConvenienceChange = (empresaId: number) => {
        setConveniencePages(prev => ({
            ...prev,
            [empresaId]: !prev[empresaId],
        }));
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar brandText={"Consolidação"} search={false} titleSearch={""} />
            <Container className="mt-4">
                <Row>
                    <Col md="6">
                        <div className="mb-3">
                            <label htmlFor="network-select" className="form-label">Selecione uma Rede:</label>
                            <select
                                id="network-select"
                                className="form-control form-control-alternative"
                                onChange={handleNetworkChange}
                                value={selectedNetwork || ''}
                            >
                                <option value="">Selecione uma rede</option>
                                {listNetworks.map((network) => (
                                    <option key={network.name} value={network.name}>
                                        {network.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mt-3">
                            <label htmlFor="month-select" className="form-label">Selecione até qual mês deseja o relatório:</label>
                            <select
                                id="month-select"
                                className="form-control form-control-alternative"
                                onChange={(e) => setSelectedMonth(e.target.value)}
                                value={selectedMonth}
                            >
                                <option value="">Selecione um mês</option>
                                <option value="Janeiro">Janeiro</option>
                                <option value="Fevereiro">Fevereiro</option>
                                <option value="Março">Março</option>
                                <option value="Abril">Abril</option>
                                <option value="Maio">Maio</option>
                                <option value="Junho">Junho</option>
                                <option value="Julho">Julho</option>
                                <option value="Agosto">Agosto</option>
                                <option value="Setembro">Setembro</option>
                                <option value="Outubro">Outubro</option>
                                <option value="Novembro">Novembro</option>
                                <option value="Dezembro">Dezembro</option>
                            </select>
                        </div>
                        <FormGroup check className="mt-3">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={hasFinalConsolidation}
                                    onChange={() => setHasFinalConsolidation(!hasFinalConsolidation)}
                                />{' '}
                                Incluir páginas de consolidação final do posto
                            </Label>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={hasFinalConsolidationConveniencia}
                                    onChange={() => setHasFinalConsolidationConveniencia(!hasFinalConsolidationConveniencia)}
                                />{' '}
                                Incluir páginas de consolidação final da conveniência
                            </Label>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={hasFinalConsolidationConvenienciaPosto}
                                    onChange={() => setHasFinalConsolidationConvenienciaPosto(!hasFinalConsolidationConvenienciaPosto)}
                                />{' '}
                                Incluir páginas de consolidação final do posto e da conveniência
                            </Label>
                        </FormGroup>
                    </Col>
                    {selectedNetwork && selectedMonth && (
                        <Col md="6" className="d-flex flex-column">
                            <div className="w-100 mb-3">
                                <div
                                    className="empresas-list p-3 rounded"
                                    style={{ backgroundColor: "#1c547c" }}
                                >
                                    <h3 className="mb-3" style={{ color: "#FFF" }}>Empresas na Rede {selectedNetwork}</h3>
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId="empresas">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {listEmpresas.map((empresa, index) => (
                                                        <Draggable key={empresa.id} draggableId={empresa.id.toString()} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className="empresa-item bg-white rounded mb-2 p-3 d-flex flex-column"
                                                                >
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        <span className="drag-handle mr-2">&#8942;</span>
                                                                        <span className="empresa-name flex-grow-1 ms-3">{empresa.name}</span>
                                                                        <button
                                                                            className="btn btn-primary btn-sm ms-2"
                                                                            style={{ color: "#FFF", backgroundColor: "#1c547c" }}
                                                                            onClick={() => togglePageModal(empresa.id)}
                                                                        >
                                                                            <i className="fas fa-file-alt"></i>
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-primary btn-sm ms-2"
                                                                            style={{ color: "#FFF", backgroundColor: "#1c547c" }}
                                                                            onClick={() => openAddModalForEmpresa(empresa.id)}
                                                                        >
                                                                            <i className="fas fa-plus"></i>
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-outline-danger btn-sm me-2"
                                                                            onClick={() => removeEmpresa(empresa.id)}
                                                                        >
                                                                            <i className="fas fa-trash-alt"></i>
                                                                        </button>
                                                                        <div className="btn-group">
                                                                            <button
                                                                                className="btn btn-outline-secondary btn-sm"
                                                                                onClick={() => moveUp(index)}
                                                                            >
                                                                                <i className="fas fa-arrow-up"></i>
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-outline-secondary btn-sm"
                                                                                onClick={() => moveDown(index)}
                                                                            >
                                                                                <i className="fas fa-arrow-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    {empresa.hasConvenience && (
                                                                        <FormGroup check style={{ fontSize: '12px', color: 'grey', textAlign: 'right' }}>
                                                                            <Label check>
                                                                                <Input
                                                                                    type="checkbox"
                                                                                    checked={conveniencePages[empresa.id] || false}
                                                                                    onChange={() => handleConvenienceChange(empresa.id)}
                                                                                />{' '}
                                                                                Habilitar páginas de conveniência
                                                                            </Label>
                                                                        </FormGroup>
                                                                    )}
                                                                    <div className="additional-pages mt-3">
                                                                        {additionalPages[empresa.id]?.map((page, pageIndex) => (
                                                                            <div key={pageIndex} className="additional-page mb-2 p-3 bg-light rounded">
                                                                                <h5>{page.title}</h5>
                                                                                <p>
                                                                                    {page.content ? page.content : page.imagem ? "Imagem" : ""}
                                                                                </p>
                                                                                <div>
                                                                                    <button
                                                                                        className="btn btn-primary btn-sm me-2"
                                                                                        style={{ color: "#FFF", backgroundColor: "#1c547c" }}
                                                                                        onClick={() => openEditModalForEmpresa(empresa.id, pageIndex)}
                                                                                    >
                                                                                        <i className="fas fa-edit"></i>
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-outline-danger btn-sm"
                                                                                        onClick={() => removePage(empresa.id, pageIndex)}
                                                                                    >
                                                                                        <i className="fas fa-trash-alt"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                            <Button
                                style={{ color: "#FFF", backgroundColor: "#1c547c" }}
                                size="m"
                                onClick={handleGeneratePdf}
                                className="mt-3"
                            >
                                Consolidar relatórios
                            </Button>
                            <Button
                                style={{ color: "#FFF", backgroundColor: "#1c547c" }}
                                size="m"
                                onClick={() => setOpenPresentation(true)}
                                className="mt-3"
                            >
                                Apresentação slides
                            </Button>
                        </Col>
                    )}
                </Row>
            </Container>

            <Modal isOpen={addPageModalOpen} toggle={toggleAddPageModal} style={{ maxWidth: '100vw', height: '100vh', margin: 0 }}>
                <ModalHeader toggle={toggleAddPageModal}>Adicionar Página</ModalHeader>
                <ModalBody style={{ height: 'calc(100vh - 150px)' }}>
                    <FormGroup>
                        <Label for="page-title">Título da Página</Label>
                        <Input
                            type="text"
                            id="page-title"
                            value={currentPageTitle}
                            onChange={e => setCurrentPageTitle(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="page-content">Conteúdo da Página</Label>
                        <Input
                            type="textarea"
                            id="page-content"
                            style={{ height: '90%' }}
                            value={currentPageContent}
                            rows={17}
                            onChange={e => setCurrentPageContent(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="page-image">Imagem da Página</Label>
                        <Input
                            type="file"
                            id="page-image"
                            accept="image/*"
                            onChange={e => {
                                if (e.target.files && e.target.files[0]) {
                                    const file = e.target.files[0];
                                    const reader = new FileReader();
                                    reader.onload = (readEvent) => {
                                        if (readEvent.target && readEvent.target.result) {
                                            setImagePageContent(readEvent.target.result as string);
                                        }
                                    };
                                    reader.readAsDataURL(file);
                                }
                            }}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" style={{ backgroundColor: "#1c547c" }} onClick={handleAddPage}>Adicionar</Button>
                    <Button color="secondary" onClick={toggleAddPageModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={editPageModalOpen} toggle={toggleEditPageModal} style={{ maxWidth: '100vw', height: '100vh', margin: 0 }}>
                <ModalHeader toggle={toggleEditPageModal}>Editar Página</ModalHeader>
                <ModalBody style={{ height: 'calc(100vh - 150px)' }}>
                    <FormGroup>
                        <Label for="page-title">Título da Página</Label>
                        <Input
                            type="text"
                            id="page-title"
                            className="form-control"
                            value={currentPageTitle}
                            onChange={(e) => setCurrentPageTitle(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="page-content">Conteúdo da Página</Label>
                        <Input
                            type="textarea"
                            id="page-content"
                            className="form-control"
                            style={{ height: '90%' }}
                            value={currentPageContent}
                            rows={17}
                            onChange={(e) => setCurrentPageContent(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="page-image">Imagem da Página</Label>
                        <Input
                            type="file"
                            id="page-image"
                            accept="image/*"
                            onChange={e => {
                                if (e.target.files && e.target.files[0]) {
                                    const file = e.target.files[0];
                                    const reader = new FileReader();
                                    reader.onload = (readEvent) => {
                                        if (readEvent.target && readEvent.target.result) {
                                            setImagePageContent(readEvent.target.result as string);
                                        }
                                    };
                                    reader.readAsDataURL(file);
                                }
                            }}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        style={{ color: "#FFF", backgroundColor: "#1c547c" }}
                        onClick={handleEditPage}
                    >
                        Editar
                    </Button>
                    <Button color="secondary" onClick={toggleEditPageModal}>Cancelar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={pageModalOpen} toggle={() => togglePageModal(currentEmpresaId)}>
                <ModalHeader toggle={() => togglePageModal(currentEmpresaId)}>Selecione as Páginas</ModalHeader>
                <ModalBody>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="page-list">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {generatePages[currentEmpresaId]?.map((page, index) => (
                                        <Draggable key={page} draggableId={`${page}`} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="page-item p-2 mb-2 bg-light rounded d-flex align-items-center justify-content-between"
                                                >
                                                    <FormGroup check>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={selectedPages[currentEmpresaId]?.[index] ?? true}
                                                                onChange={() => handleCheckboxChange(index)}
                                                            />{' '}
                                                            Página {page}: {page <= 19 ? pageNames[page - 1] : `Adicional ${page - 19}`}
                                                        </Label>
                                                    </FormGroup>
                                                    <span className="drag-handle">&#8942;</span>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        style={{ color: "#FFF", backgroundColor: "#1c547c" }}
                        onClick={() => setPageModalOpen(false)}
                    >
                        Confirmar
                    </Button>
                    <Button color="secondary" onClick={() => setPageModalOpen(false)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
            <ModalPresentation
                empresas={listEmpresas.map((empresa) => ({
                    id: empresa.id,
                    name: empresa.name,
                    generatePages: generatePages[empresa.id]?.filter((page, index) => selectedPages[empresa.id] && selectedPages[empresa.id][index]),
                    additionalPages: additionalPages[empresa.id] || [],
                    hasConvenience: conveniencePages[empresa.id] || false,
                }))}
                hasFinalConsolidation={hasFinalConsolidation}
                hasFinalConsolidationConveniencia={hasFinalConsolidationConveniencia}
                hasFinalConsolidationConvenienciaPosto={hasFinalConsolidationConvenienciaPosto}
                selectedMonth={selectedMonth}
                open={openPresentation}
                handleClose={() => setOpenPresentation(false)}
            />
        </>
    );
};

export default Consolidation;