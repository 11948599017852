import Profile from "./views/screensCandidate/Profile.tsx";
import Login from "./views/Login.tsx";
import Tables from "./views/screensCandidate/Tables.tsx";
import SearchJobs from "./views/screensCandidate/SearchJobs/SearchJobs.tsx";
import SearchCandidates from "./views/screensRecruiter/SearchCandidates/SearchCandidates.tsx";
import IndicatorMenu from "./views/screensAdmin/IndicatorMenu.tsx";
import Dashboard from "./views/screensCandidate/Dashboard.tsx";
import ProfileRecruiter from "./views/screensRecruiter/Profile.tsx";
import AddUser from "./views/screensAdmin/AddUser.tsx";
import Home from "./views/Home.tsx";
import Consolidation from "./views/screensAdmin/Consolidation.tsx";
import Database from "./views/screensAdmin/Database.tsx";
import PresentationHistory from "./views/screensAdmin/PresentationHistory.tsx";
import PresentationHistoryEmpresa from "./views/screensCandidate/PresentationHistory.tsx";
import ConsultingHoursRatio from "./views/screensAdmin/ConsultingHoursRatio.tsx";
import Excel from "./views/screensAdmin/Excel.tsx";
import LotsManagement from "./views/screensAdmin/LotsManagement.tsx";

export const routes = [
  {
    path: "/index",
    name: "Página Inicial",
    icon: "fa fa-home text-yellow",
    component: <Home />,
    layout: "/admin",
  },
  {
    path: "/history",
    name: "Histórico",
    icon: "fa-solid fa-box-archive text-blue",
    component: <PresentationHistoryEmpresa />,
    layout: "/admin",
  },
  // {
  //   path: "/search-jobs",
  //   name: "Procurar Vagas",
  //   icon: "ni ni-planet text-blue",
  //   component: <SearchJobs />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/profile",
  //   name: "Meu Perfil",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: <Profile />,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

export const routesRecruiter = [
  {
    path: "/index",
    name: "Página Inicial",
    icon: "fa fa-home text-yellow",
    component: <Home />,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Meu Perfil",
    icon: "ni ni-single-02 text-yellow",
    component: <ProfileRecruiter />,
    layout: "/admin",
  },
  {
    path: "/search-candidates",
    name: "Procurar Candidatos",
    icon: "ni ni-planet text-blue",
    component: <SearchCandidates />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

export const routesAdmin = [
  {
    path: "/excel",
    name: "Excel",
    icon: "fa-regular fa-file-excel text-green",
    component: <Excel />,
    layout: "/admin",
  },
  {
    path: "/consultingHoursRatio",
    name: "Horas de Consultoria",
    icon: "fa-regular fa-clock text-yellow",
    component: <ConsultingHoursRatio />,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Adicionar empresa",
    icon: "fa-solid fa-plus text-green",
    component: <AddUser />,
    layout: "/admin",
  },
  {
    path: "/database",
    name: "Banco de Dados",
    icon: "fa-solid fa-folder-open text-blue",
    component: <Database />,
    layout: "/admin",
  },
  {
    path: "/consolidation",
    name: "Consolidação",
    icon: "fa-solid fa-file-lines text-blue",
    component: <Consolidation />,
    layout: "/admin",
  },
  {
    path: "/history",
    name: "Histórico",
    icon: "fa-solid fa-box-archive text-blue",
    component: <PresentationHistory />,
    layout: "/admin",
  },
  {
    path: "/lots",
    name: "Lotes",
    icon: "fa-regular fa-file text-orange",
    component: <LotsManagement />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Sair",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];