import { ReactNode, createContext } from "react";
import useEmpresas, { EmpresasType, NetworksType } from "./hooks/useEmpresas";
import useHours, { HoursType } from "./hooks/useHours";

interface HoursContextProviderProps {
    children: ReactNode;
}

export interface HoursContextData {
    createHours: (hoursData: HoursType) => Promise<HoursType | null>;
    updateHours: (hoursData: HoursType) => Promise<HoursType | null>;
    getAllHoursPaged: (pageNumber: number, name?: string) => Promise<void>;
    listHours: HoursType[];
    pageCount: number;
    initialListHours: HoursType[];
}

const initialState: HoursContextData = {
    createHours: async () => null,
    updateHours: async () => null,
    getAllHoursPaged: async () => {},
    listHours: [],
    pageCount: 0,
    initialListHours: [],
};

export const HoursContext =
    createContext<HoursContextData>(initialState);

export default function HoursContextProvider({
    children,
}: HoursContextProviderProps) {
    const {
        createHours,
        updateHours,
        getAllHoursPaged,
        listHours,
        pageCount,
        initialListHours,
    } = useHours();

    return (
        <HoursContext.Provider
            value={{
                createHours,
                updateHours,
                getAllHoursPaged,
                listHours,
                pageCount,
                initialListHours,
            }}
        >
            {children}
        </HoursContext.Provider>
    );
}
