import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { set } from "lodash";

export interface HoursType {
    id: number;
    idEmpresa: number;
    name: string;
    tecnico?: string;
    status?: boolean;
    network: string;
    mesReferencia: string;
    anoReferencia: number;
    horasContratadas: number;
    horasRealizadas: number;
    dia?: number;
    horaInicial?: string;
    horaFinal?: string;
}

export default function useHours() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [listHours, setListHours] = useState<HoursType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListHours, setInitialListHours] = useState<HoursType[]>([]);

    async function createHours(hoursData: HoursType): Promise<HoursType | null> {
        setLoading(true);
        try {
            let response = await api.post(`/ConsultingHoursRatio/create`, hoursData);
            setLoading(false);
            showToast("success", "Horas cadastradas com sucesso!");
            return response.data;
        } catch (error) {
            setLoading(false);
            showToast("error", "Houve um erro ao cadastrar as horas, tente novamente.", error);
            return null;
        }
    }

    async function updateHours(hoursData: HoursType): Promise<HoursType | null> {
        setLoading(true);
        console.log(hoursData);
        try {
            const response = await api.put(`/ConsultingHoursRatio/update`, hoursData);
            setLoading(false);
            showToast("success", "Horas atualizadas com sucesso!");
            return response.data;
        } catch (error) {
            setLoading(false);
            showToast("error", "Houve um erro ao atualizar as horas, tente novamente.", error);
            return null;
        }
    }

    async function getAllHoursPaged(
        pageNumber: number,
        name?: string,
    ) {
        setLoading(true);

        let url = `/ConsultingHoursRatio/getConsultingHoursRatioPaged?pageNumber=${pageNumber}&pageSize=10`;

        if (name) {
            url = url + `&name=${name}`;
        }

        try {
            const response = await api.get(url);
            setListHours(response.data.result);
            setInitialListHours(response.data.result);
            setPageCount(response.data.pageCount);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar usuários:", error);
            return null;
        }
    }

    return {
        createHours,
        updateHours,
        getAllHoursPaged,
        listHours,
        setListHours,
        pageCount,
        initialListHours
    };
}