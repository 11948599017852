import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { set } from "lodash";

export interface HistoryType {
    id: number;
    network: string;
    createdData: string;
    viewingDate: string;
}

export default function useHistory() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [historiesList, setHistoriesList] = useState<HistoryType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListHistories, setInitialListHistories] = useState<HistoryType[]>([]);

    async function updateViewingDate(id: number) {
        setLoading(true);
        let url = `/PresentationHistory/updateviewingdate?id=${id}`;

        try {
            return await api.get(url);
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar históricos:", error);
            return [];
        }
    } 

    async function getAllHistories() {
        setLoading(true);
        let url = `/PresentationHistory/getall`;

        try {
            const response = await api.get<HistoryType[]>(url);
            setHistoriesList(response.data);
            setLoading(false);
            return response.data; 
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar históricos:", error);
            return [];
        }
    }

    async function getAllHistoriesPaged(
        pageNumber: number,
        name?: string,
        network?: string
    ) {
        setLoading(true);

        let url = `/PresentationHistory/getPresentationHistoryPaged?pageNumber=${pageNumber}&pageSize=10`;

        if (name) {
            url = url + `&name=${name}`;
        }
        if (network) {
            url = url + `&network=${network}`;
        }

        try {
            const response = await api.get(url);
            setHistoriesList(response.data.result);
            setInitialListHistories(response.data.result);
            setPageCount(response.data.pageCount);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar históricos:", error);
            return null;
        }
    }

    async function getHistoriesById(id: number) {
        setLoading(true);
        let url = `/PresentationHistory/getbyid?id=${id}`;

        try {
            const response = await api.get<HistoryType[]>(url);
            setHistoriesList(response.data);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar históricos:", error);
            return [];
        }
    }

    async function getPresentationByNetwork(id: number) {
        setLoading(true);
        let url = `/PresentationHistory/getpresentation?id=${encodeURIComponent(id)}`;
    
        try {
            const response = await api.get(url);
            setLoading(false);
            return response.data; 
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar a apresentação:", error);
            showToast("Erro ao buscar a apresentação.", "error");
            return null;
        }
    }
    

    return { 
        pageCount,
        getAllHistories,
        getHistoriesById,
        historiesList,
        setHistoriesList,
        getPresentationByNetwork,
        updateViewingDate,
        getAllHistoriesPaged,
    };
}