import { ReactNode, createContext } from "react";
import usePresentation, { AdditionalPages, PresentationType } from "./hooks/usePresentation";

interface PresentationContextProviderProps {
    children: ReactNode;
}

export interface PresentationContextData {
    generatePdf: (empresas: PresentationType[], selectedMonth: string, hasFinalConsolidation: boolean, hasFinalConsolidationConveniencia: boolean, hasFinalConsolidationConvenienciaPosto: boolean) => void;
    handleGenerateHTML: (
        empresas: PresentationType[],
        selectedMonth: string,
        hasFinalConsolidation: boolean,
        hasFinalConsolidationConveniencia: boolean,
        hasFinalConsolidationConvenienciaPosto: boolean
    ) => any;
    htmlApresentation: string,
    setHtmlApresentation: any,
}

const initialState: PresentationContextData = {
    generatePdf: () => { },
    handleGenerateHTML: () => { },
    htmlApresentation: "",
    setHtmlApresentation() { },
};

export const PresentationContext =
    createContext<PresentationContextData>(initialState);

export default function PresentationContextProvider({
    children,
}: PresentationContextProviderProps) {
    const {
        generatePdf,
        handleGenerateHTML,
        htmlApresentation,
        setHtmlApresentation,
    } = usePresentation();

    return (
        <PresentationContext.Provider
            value={{
                generatePdf,
                handleGenerateHTML,
                htmlApresentation,
                setHtmlApresentation,
            }}
        >
            {children}
        </PresentationContext.Provider>
    );
}
