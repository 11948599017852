import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { PresentationContext } from "../../context/PresentationContext";
import "reveal.js/dist/reveal.css";
import "reveal.js/dist/theme/black.css";
import "./ModalPresentation.css";

interface ModalPresentationProps {
  open: boolean;
  handleClose: any;
  empresas: any;
  selectedMonth: any;
  hasFinalConsolidation: any;
  hasFinalConsolidationConveniencia: any;
  hasFinalConsolidationConvenienciaPosto: any;
}

const ModalPresentation: React.FC<ModalPresentationProps> = ({
  open,
  handleClose,
  empresas,
  selectedMonth,
  hasFinalConsolidation,
  hasFinalConsolidationConveniencia,
  hasFinalConsolidationConvenienciaPosto
}) => {
  const { handleGenerateHTML } = useContext(PresentationContext);
  const [slides, setSlides] = useState<string[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getHtml = async () => {
      if (open) {
        const htmlContent = await handleGenerateHTML(
          empresas,
          selectedMonth,
          hasFinalConsolidation,
          hasFinalConsolidationConveniencia,
          hasFinalConsolidationConvenienciaPosto
        );
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, "text/html");
        const sections = Array.from(doc.querySelectorAll("section"));
        const slidesContent = sections.map((section) => section.outerHTML);
        setSlides(slidesContent);
        setActiveIndex(0);
      }
    };
    getHtml();
  }, [open]);

  useEffect(() => {
    if (open && contentRef.current) {
      const container = contentRef.current;
      container.innerHTML = slides[activeIndex];
      container.style.opacity = '1';

      container.querySelectorAll("script").forEach((script) => {
        const scriptElement = document.createElement("script");
        scriptElement.text = script.textContent || "";
        document.body.appendChild(scriptElement);
        document.body.removeChild(scriptElement);
      });
    }
  }, [open, activeIndex, slides]);

  const transitionSlides = (newIndex: number) => {
    const container = contentRef.current;
    if (container) {
      setTransitioning(true);
      container.style.opacity = '0';
      setTimeout(() => {
        setActiveIndex(newIndex);
        setTransitioning(false);
      }, 300);
    }
  };

  const next = () => {
    transitionSlides((activeIndex + 1) % slides.length);
  };

  const previous = () => {
    transitionSlides((activeIndex - 1 + slides.length) % slides.length);
  };

  return (
    <Modal
      isOpen={open}
      toggle={handleClose}
      backdropClassName="custom-modal-backdrop"
      style={{ maxWidth: "100vw", height: "100vh", margin: 0 }}
      className="full-screen-modal"
    >
      <ModalBody style={{ padding: 0, position: 'relative', display: "flex", flexDirection: "column", height: '100%' }}>
        {/* {!(activeIndex === 0 || activeIndex === 1 || activeIndex === slides.length - 1) && (
          <img
            style={{ width: "100%", position: 'absolute', top: 0 }}
            alt="..."
            src={require("../../assets/img/brand/IMG_6921.PNG")}
          />
        )} */}
        {!(activeIndex === 0 || activeIndex === 1 || activeIndex === slides.length - 1) && (
          <>
            <img
              style={{ width: "100%", position: 'absolute', top: 0 }}
              alt="..."
              src={require("../../assets/img/brand/IMG_6921.PNG")}
            />
            <div style={{ position: 'absolute', bottom: '10px', left: '10px', width: '2%', height: '5%' }}>
              <img
                style={{ width: "100%", height: "100%", position: 'absolute', zIndex: 1 }}
                alt="..."
                src={require("../../assets/img/brand/Gota.png")}
              />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -40%)',
                  zIndex: 2,
                  color: 'black',
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}
              >
                {activeIndex}
              </div>
            </div>
          </>
        )}
        <div
          ref={contentRef}
          style={{
            flex: 1,
            width: "100%",
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden',
            transition: 'opacity 0.3s ease',
          }}
        />
        {slides.length > 1 && (
          <Row
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              textAlign: "center",
              overflow: 'hidden',
            }}
          >
            <Col>
              <Button
                style={{ fontSize: '0.8rem', padding: '5px 10px', marginBottom: '10px' }}
                color="primary"
                onClick={previous}
                disabled={activeIndex === 0 || transitioning}
              >
                Anterior
              </Button>
              <Button
                style={{ fontSize: '0.8rem', padding: '5px 10px', marginBottom: '10px' }}
                color="primary"
                onClick={next}
                disabled={activeIndex === slides.length - 1 || transitioning}
              >
                Próximo
              </Button>
              <Button style={{ fontSize: '0.8rem', padding: '5px 10px', marginBottom: '10px' }} color="secondary" onClick={handleClose}>
                Fechar
              </Button>
            </Col>
          </Row>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalPresentation;