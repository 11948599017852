import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { set } from "lodash";

export interface EmpresasType {
    id: number;
    name: string;
    network: string;
    videoLink?: string;
    consultingHours?: number;
    hasConvenience?: boolean;
}

export interface NetworksType {
    name: string;
}

export default function useEmpresas() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [listNetworks, setListNetworks] = useState<NetworksType[]>([]);
    const [listEmpresas, setListEmpresas] = useState<EmpresasType[]>([]);
    const [linkVideo, setLinkVideo] = useState<string>("");

    async function getAllNetworks() {
        setLoading(true);
        let url = `/Empresas/getNetworks`;

        try {
            const response = await api.get<string[]>(url);
            const networks = response.data.map(name => ({ name })); 
            setListNetworks(networks);
            setLoading(false);
            return networks; 
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar redes:", error);
            return null;
        }
    }

    async function getVideoLink(id: number) {
        setLoading(true);

        let url = `/Empresas/getVideoByUserId?userId=${id}`;

        try {
            const response = await api.get(url);
            setLinkVideo(response.data);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar video link:", error);
            return null;
        }
    }

    async function getEmpresasByNetwokr(network: string) {
        setLoading(true);

        let url = `/Empresas/getEmpresasByNetwork?network=${network}`;

        try {
            const response = await api.get(url);
            setListEmpresas(response.data);
            setLoading(false);
            return response.data;
        } catch (error) {
            setLoading(false);
            console.error("Erro ao buscar empresas:", error);
            return null;
        }
    }

    return { 
        getAllNetworks,
        getEmpresasByNetwokr,
        setListEmpresas,
        setListNetworks,
        getVideoLink,
        linkVideo,
        listNetworks,
        listEmpresas
    };
}