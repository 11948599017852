import { ReactNode, createContext } from "react";
import useEmpresas, { EmpresasType, NetworksType } from "./hooks/useEmpresas";

interface EmpresasContextProviderProps {
    children: ReactNode;
}

export interface EmpresasContextData {
    getAllNetworks: () => Promise<NetworksType[] | null>;
    getEmpresasByNetwokr: (network: string) => Promise<EmpresasType[] | null>;
    getVideoLink: (id: number) => Promise<string | null>;
    setListEmpresas: (empresas: EmpresasType[]) => void;
    setListNetworks: (networks: NetworksType[]) => void;
    linkVideo: string;
    listNetworks: NetworksType[];
    listEmpresas: EmpresasType[];
}

const initialState: EmpresasContextData = {
    getAllNetworks: async () => {
        return Promise.resolve(null);
    },
    getEmpresasByNetwokr: async (network: string) => {
        return Promise.resolve(null);
    },
    getVideoLink: async (id: number) => {
        return Promise.resolve(null);
    },
    setListEmpresas: () => {},
    setListNetworks: () => {},
    linkVideo: "",
    listNetworks: [],
    listEmpresas: [],
};

export const EmpresasContext =
    createContext<EmpresasContextData>(initialState);

export default function EmpresasContextProvider({
    children,
}: EmpresasContextProviderProps) {
    const {
        getAllNetworks,
        getEmpresasByNetwokr,
        getVideoLink,
        setListEmpresas,
        setListNetworks,
        linkVideo,
        listNetworks,
        listEmpresas
    } = useEmpresas();

    return (
        <EmpresasContext.Provider
            value={{
                getAllNetworks,
                getEmpresasByNetwokr,
                getVideoLink,   
                setListEmpresas,
                setListNetworks,
                linkVideo,
                listNetworks,
                listEmpresas
            }}
        >
            {children}
        </EmpresasContext.Provider>
    );
}
