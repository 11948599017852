import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { set } from "lodash";

export interface LotsType {
    id: number;
    empresa: string;
    network: string;
    mesReferencia: string;
    anoVigencia: number;
    submissionDate: Date;
}

export default function useLots() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const [lots, setLots] = useState<LotsType>({
        id: 0,
        empresa: "",
        network: "",
        mesReferencia: "",
        anoVigencia: 0,
        submissionDate: new Date()
    });
    const [listLots, setListLots] = useState<LotsType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListLots, setInitialListLots] = useState<LotsType[]>([]);

    function getAllLotsPaged(
        pageNumber: number,
    ): Promise<LotsType[] | null> {
        setLoading(true);

        let url = `/Lots/getAllLotsPaged?`;

        if (pageNumber !== 0) {
            url = url + `pageNumber=${pageNumber}&pageSize=9`;
        }
        return api
            .get(url)
            .then((response) => {
                setListLots(response.data.result);
                setInitialListLots(response.data.result);
                setPageCount(response.data.pageCount);
                setLoading(false);
                return response.data.result;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao carregar lotes. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao carregar lotes:", error);
                return null;
            });
    }

    async function deleteLots(lotId: number) {
        setLoading(true);
        try {
            const response = await api.delete(`/Lots/delete/${lotId}`);
            setLoading(false);
            showToast("success", "Dados do lote excluídos com sucesso!");
            return response.data;
        } catch (error: any) {
            setLoading(false);
            showToast("error", "Houve um erro ao excluir os dados do lote, tente novamente.", error);
            console.error("Erro ao excluir empresa:", error);
            throw new Error(error.response.data || "Erro desconhecido ao excluir dados do lote");
        }
    }    

    return {
        lots,
        setLots,
        listLots,
        setListLots,
        initialListLots,
        setInitialListLots,
        pageCount,
        setPageCount,
        getAllLotsPaged,
        deleteLots
    };
}