import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col, Input, Modal, ModalHeader, ModalBody, Form, FormGroup, Label } from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import { AuthContext } from '../../context/AuthContext';
import useLots, { LotsType } from '../../context/hooks/useLots';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const LotsManagement = () => {
    const { objUserTk } = useContext(AuthContext);
    const { listLots, pageCount, getAllLotsPaged, deleteLots } = useLots();

    const [loading, setLoading] = useState(false);
    // const [dataSearch, setDataSearch] = useState({
    //     customerName: '',
    //     routineDescription: '',
    //     startDate: '',
    //     endDate: '',
    //     status: 0,
    // });
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setLoading(true);
        getAllLotsPaged(1)
            .then(data => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar dados do lote:", error);
                setLoading(false);
            });
    }, []);

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllLotsPaged(value);
    };

    // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setDataSearch({ ...dataSearch, [e.target.name]: e.target.value });
    // };

    // const handleSearchSubmit = () => {
    //     setLoading(true);
    //     getAllTaxTaskPaged(
    //         1,
    //         0,
    //         objUserTk.OfficeId,
    //         dataSearch.status,
    //         dataSearch.customerName,
    //         dataSearch.routineDescription,
    //         dataSearch.startDate,
    //         dataSearch.endDate
    //     )
    //         .then(() => setLoading(false))
    //         .catch(() => setLoading(false));
    // };

    // const handleClearFilters = () => {
    //     setDataSearch({
    //         customerName: '',
    //         routineDescription: '',
    //         startDate: '',
    //         endDate: '',
    //         status: 0
    //     });
    //     setLoading(true);
    //     getAllTaxTaskPaged(1, 0, objUserTk.OfficeId)
    //         .then(() => setLoading(false))
    //         .catch(() => setLoading(false));
    // };

    const handleDeleteLots = (lotId: number) => {
        MySwal.fire({
            title: 'Tem certeza?',
            text: "Esta ação não pode ser desfeita!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar'
        }).then((result: any) => {
            if (result.isConfirmed) {
                setLoading(true);
                deleteLots(lotId)
                    .then(() => {
                        MySwal.fire(
                            'Deletado!',
                            'O lote foi excluído com sucesso.',
                            'success'
                        );
                        return getAllLotsPaged(1);
                    })
                    .catch((error) => {
                        MySwal.fire(
                            'Erro!',
                            'Houve um problema ao excluir o lote.',
                            'error'
                        );
                    })
                    .finally(() => setLoading(false));
            }
        });
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Gerenciar Lotes"}
                search={false}
                titleSearch={""}
            />

            <Container className="mt-5">
                <Row>
                    {/* <Col xs="12">
                        <Form className="d-flex search-form">
                            <FormGroup>
                                <Label for="customerName">Cliente</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="customerName"
                                    id="customerName"
                                    placeholder="Buscar por cliente"
                                    value={dataSearch.customerName}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="routineDescription">Rotina</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="routineDescription"
                                    id="routineDescription"
                                    placeholder="Descrição da rotina"
                                    value={dataSearch.routineDescription}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="startDate">Data Inicial</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="date"
                                    name="startDate"
                                    id="startDate"
                                    placeholder="Data inicial"
                                    value={dataSearch.startDate}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="endDate">Data Final</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="date"
                                    name="endDate"
                                    id="endDate"
                                    placeholder="Data final"
                                    value={dataSearch.endDate}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                            <Button className="search-btn" onClick={handleSearchSubmit}>Buscar</Button>
                            <Button className="clear-btn" onClick={handleClearFilters}>Limpar</Button>
                            <Button className="export-btn" onClick={handleExport}>Exportar</Button>
                        </Form>
                    </Col> */}
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Table hover responsive className="table-striped custom-table">
                            <thead>
                                <tr>
                                    <th>Número do lote</th>
                                    <th>Empresa</th>
                                    <th>Rede</th>
                                    <th>Mês de referência dos dados</th>
                                    <th>Ano de vigência</th>
                                    <th>Data de submissão</th>
                                    <th>Remover dados do lote</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listLots.map((lots: LotsType, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{lots.id}</td>
                                            <td>{lots.empresa}</td>
                                            <td>{lots.network}</td>
                                            <td>{lots.mesReferencia}</td>
                                            <td>{lots.anoVigencia}</td>
                                            <td>{new Date(lots.submissionDate).toLocaleDateString()}</td>
                                            <td>
                                                <Button onClick={() => handleDeleteLots(lots.id)} color="danger"><i className="fa-solid fa-trash"></i></Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listLots.length === 0 && (
                                <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default LotsManagement;
