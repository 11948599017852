import { Fragment, useContext, useEffect, useState, FormEvent } from "react";
import { Container, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Header from "../../components/Headers/Header";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import useHours, { HoursType } from "../../context/hooks/useHours";
import { EmpresasContext } from "../../context/EmpresasContext";

const ConsultingHoursRatio = () => {
    const [loading, setLoading] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState("");
    const [selectedEmpresa, setSelectedEmpresa] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [horasRealizadas, setHorasRealizadas] = useState("");
    const [hoursData, setHoursData] = useState<HoursType>({
        id: 0,
        idEmpresa: 0,
        name: "",
        tecnico: "",
        status: true,
        network: "",
        mesReferencia: "",
        anoReferencia: 0,
        horasContratadas: 0,
        horasRealizadas: 0,
        dia: 1,
        horaFinal: "",
        horaInicial: ""
    });
    const [hours, setHours] = useState<HoursType[]>([]);
    const [editingHours, setEditingHours] = useState<HoursType | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);

    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [dataSearch, setDataSearch] = useState({ name: "" });

    const {
        createHours,
        updateHours,
        getAllHoursPaged,
        listHours,
        pageCount,
        initialListHours,
    } = useHours();

    const {
        listEmpresas,
        getAllNetworks,
        getEmpresasByNetwokr,
        listNetworks,
        setListEmpresas }
        = useContext(EmpresasContext);


    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const toggleErrorModal = () => setErrorModal(!errorModal);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setHoursData(prevData => ({
            ...prevData,
            [name]: name === "anoReferencia" || name === "horasRealizadas" ? parseInt(value) : value
        }));
    };

    useEffect(() => {
        setLoading(true);
        getAllNetworks();
        getAllHoursPaged(1)
            .then(data => {
                setHours(data || []);
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar relações de consultoria:", error);
                setLoading(false);
            });
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        createHours(hoursData).then(newHours => {
            setLoading(false);
            if (newHours) {
                toggleSuccessModal();
            } else {
                toggleErrorModal();
            }
        }).catch(error => {
            setLoading(false);
            console.error("Erro ao criar relação de horas:", error);
            setErrorMessage(error.message || "Erro desconhecido");
            toggleErrorModal();
        });
    };

    const handleEditHours = (hours: HoursType) => {
        setEditingHours(hours);
        setEditModalOpen(true);
    };

    const onChangePaginationCommon = async (e: any, value: any) => {

        await getAllHoursPaged(value);
    };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentPage(1);
        setLoading(true);
        getAllHoursPaged(1, dataSearch.name)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingHours) {
            updateHours(editingHours)
                .then(updatedHours => {
                    if (updatedHours) {
                        console.log("Relação atualizada com sucesso:", updatedHours);
                        toggleSuccessModal();
                        getAllHoursPaged(1);
                        clearFormFields();
                    } else {
                        console.error("Erro ao atualizar relação.");
                        toggleErrorModal();
                        getAllHoursPaged(1);
                        clearFormFields();
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar relação:", error);
                    toggleErrorModal();
                    getAllHoursPaged(1);
                    clearFormFields();
                });
        }
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const parsedValue = value === "true" ? true : value === "false" ? false : value;

        setEditingHours(prevHours => ({
            ...prevHours!,
            [name]: parsedValue,
        }));
    };

    const handleNetworkChange = async (networkId: string) => {
        const network = listNetworks.find(n => n.name.toString() === networkId);
        setHoursData(prevData => ({
            ...prevData,
            network: network ? network.name : ""
        }));
        await getEmpresasByNetwokr(networkId);
    };

    const handleModalToggle = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCreateNewHoursEntry = async (hoursData: HoursType) => {
        setLoading(true);

        try {
            await createHours(hoursData);
            console.log("Registro de horas criado com sucesso!");
            handleModalToggle();
        } catch (error) {
            console.error("Erro ao criar registro de horas:", error);
        } finally {
            getAllHoursPaged(1)
            clearFormFields();
            setLoading(false);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataSearch({ ...dataSearch, name: e.target.value });
    };

    const clearFormFields = () => {
        setHoursData({
            id: 0,
            idEmpresa: 0,
            name: "",
            tecnico: "",
            status: true,
            network: "",
            mesReferencia: "",
            anoReferencia: 0,
            horasContratadas: 0,
            horasRealizadas: 0,
            dia: 1,
            horaFinal: "",
            horaInicial: ""
        });
        setSelectedNetwork("");
        setSelectedEmpresa("");
        setSelectedMonth("");
        setSelectedYear("");
        setHorasRealizadas("");
    };

    const monthsOptions = [
        { value: "", label: "Selecione um mês" },
        { value: "Janeiro", label: "Janeiro" },
        { value: "Fevereiro", label: "Fevereiro" },
        { value: "Março", label: "Março" },
        { value: "Abril", label: "Abril" },
        { value: "Maio", label: "Maio" },
        { value: "Junho", label: "Junho" },
        { value: "Julho", label: "Julho" },
        { value: "Agosto", label: "Agosto" },
        { value: "Setembro", label: "Setembro" },
        { value: "Outubro", label: "Outubro" },
        { value: "Novembro", label: "Novembro" },
        { value: "Dezembro", label: "Dezembro" },
    ];

    const currentYear = new Date().getFullYear();
    const yearsOptions = Array.from({ length: 10 }, (_, index) => ({ value: `${currentYear - index}`, label: `${currentYear - index}` }));

    return (
        <>
            {loading && <LoadingSpinner />}
            <Header />
            <AdminNavbar
                brandText={"Consultar relação das horas de consultoria"}
                search={false}
                titleSearch={""}
            />
            <Container className="mt-5">
                {/* <Button color="primary" onClick={() => setIsModalOpen(true)} style={{ marginBottom: '1rem', color: "#FFF", backgroundColor: "#1c547c" }}>
                    Adicionar Registro
                </Button> */}
                <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} onClick={handleModalToggle}>Adicionar Nova Relação de Horas</Button>

                <div className="mb-4"></div>

                <Row>
                    <Col xs="6">
                        <form className="d-flex" onSubmit={handleSearchSubmit}>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="search"
                                id="search"
                                placeholder="Buscar por nome"
                                value={dataSearch.name}
                                onChange={handleSearchChange}
                                style={{ width: "75%" }}
                            />
                            <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} type="submit" size="m">Buscar</Button>
                        </form>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Tecnico</th>
                                    <th>Mês</th>
                                    <th>Ano</th>
                                    <th>Horas realizadas</th>
                                    <th>Horas contratadas</th>
                                    <th>Dia</th>
                                    <th>Hora inicial</th>
                                    <th>Hora final</th>
                                    <th>Status</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listHours.map((hours: HoursType, index: number) => (
                                    <tr key={index}>
                                        <td>{hours.name}</td>
                                        <td>{hours.tecnico}</td>
                                        <td>{hours.mesReferencia}</td>
                                        <td>{hours.anoReferencia}</td>
                                        <td>{hours.horasRealizadas}</td>
                                        <td>{hours.horasContratadas}</td>
                                        <td>{hours.dia}</td>
                                        <td>{hours.horaInicial}</td>
                                        <td>{hours.horaFinal}</td>
                                        {/* <td>{hours.status ? "Ativo" : "Inativo"}</td> */}
                                        <td style={{ color: hours.status ? "green" : "red" }}>
                                            {hours.status ? "Ativo" : "Inativo"}
                                        </td>

                                        <td>
                                            <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} onClick={() => handleEditHours(hours)}><i className="fa-solid fa-pen-to-square"></i></Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listHours.length === 0 && (
                                <div style={{ color: "red" }}>ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Modal de adição */}
            <Modal isOpen={isModalOpen} toggle={handleModalToggle}>
                <ModalHeader toggle={handleModalToggle}>Criar Nova Relação de Horas</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleCreateNewHoursEntry(hoursData);
                    }}>
                        <FormGroup>
                            <Label for="networkSelect">Rede:</Label>
                            <Input className="form-control form-control-alternative" type="select" name="network" id="networkSelect" value={hoursData.network} onChange={(e) => handleNetworkChange(e.target.value)}>
                                <option value="">Selecione uma rede...</option>
                                {listNetworks.map((network) => (
                                    <option key={network.name} value={network.name}>{network.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="empresaSelect">Empresa:</Label>
                            <Input className="form-control form-control-alternative" type="select" name="name" id="empresaSelect" value={hoursData.name} onChange={handleInputChange}>
                                <option value="">Selecione uma empresa...</option>
                                {listEmpresas.map((empresa) => (
                                    <option key={empresa.name} value={empresa.name}>{empresa.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="tecnico">Técnico:</Label>
                            <Input className="form-control form-control-alternative" type="text" name="tecnico" id="tecnico" value={hoursData.tecnico} onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="monthSelect">Mês:</Label>
                            <Input className="form-control form-control-alternative" type="select" name="mesReferencia" id="monthSelect" value={hoursData.mesReferencia} onChange={handleInputChange}>
                                {monthsOptions.map((month) => (
                                    <option key={month.value} value={month.value}>
                                        {month.label}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="yearSelect">Ano:</Label>
                            <Input className="form-control form-control-alternative" type="select" name="anoReferencia" id="yearSelect" value={hoursData.anoReferencia.toString()} onChange={handleInputChange}>
                                <option value="">Selecione o ano...</option>
                                {yearsOptions.map((year) => (
                                    <option key={year.value} value={year.value}>
                                        {year.label}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="dia">Dia:</Label>
                            <Input className="form-control form-control-alternative" type="number" name="dia" id="dia" value={hoursData.dia} onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="horaInicial">Hora Inicial:</Label>
                            <Input className="form-control form-control-alternative" type="time" name="horaInicial" id="horaInicial" value={hoursData.horaInicial} onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="horaFinal">Hora Final:</Label>
                            <Input className="form-control form-control-alternative" type="time" name="horaFinal" id="horaFinal" value={hoursData.horaFinal} onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="horasRealizadas">Horas Realizadas:</Label>
                            <Input className="form-control form-control-alternative" type="number" name="horasRealizadas" id="horasRealizadas" value={hoursData.horasRealizadas.toString()} onChange={handleInputChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="status">Status:</Label>
                            <Input className="form-control form-control-alternative" type="select" name="status" id="status" value={hoursData.status ? "true" : "false"} onChange={handleInputChange}>
                                <option value="true">Ativo</option>
                                <option value="false">Inativo</option>
                            </Input>
                        </FormGroup>
                        <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} type="submit">Salvar</Button>
                    </Form>
                </ModalBody>
            </Modal>


            {/* Modal de sucesso */}
            <Modal isOpen={successModal} toggle={toggleSuccessModal}>
                <ModalHeader toggle={toggleSuccessModal}>Sucesso</ModalHeader>
                <ModalBody>
                    A operação foi concluída com sucesso.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleSuccessModal}>OK</Button>
                </ModalFooter>
            </Modal>

            {/* Modal de erro */}
            <Modal isOpen={errorModal} toggle={toggleErrorModal}>
                <ModalHeader toggle={toggleErrorModal}>Erro</ModalHeader>
                <ModalBody>
                    {errorMessage || "Ocorreu um erro durante a operação."}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleErrorModal}>OK</Button>
                </ModalFooter>
            </Modal>

            {/* Modal de edição */}
            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)}>
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Inserir horas</ModalHeader>
                <ModalBody>
                    {editingHours && (
                        <Form onSubmit={handleEditSubmit}>
                            <FormGroup>
                                <Label for="networkSelect">Rede:</Label>
                                <Input className="form-control form-control-alternative" type="select" name="network" id="networkSelect" value={editingHours.network} onChange={handleEditInputChange}>
                                    <option value="">Selecione uma rede...</option>
                                    {listNetworks.map((network) => (
                                        <option key={network.name} value={network.name}>{network.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="empresaSelect">Empresa:</Label>
                                <Input className="form-control form-control-alternative" type="select" name="name" id="empresaSelect" value={editingHours.name} onChange={handleEditInputChange}>
                                    <option value="">Selecione uma empresa...</option>
                                    {listEmpresas.map((empresa) => (
                                        <option key={empresa.name} value={empresa.name}>{empresa.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="tecnico">Técnico:</Label>
                                <Input className="form-control form-control-alternative" type="text" name="tecnico" id="tecnico" value={editingHours.tecnico} onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="monthSelect">Mês:</Label>
                                <Input className="form-control form-control-alternative" type="select" name="mesReferencia" id="monthSelect" value={editingHours.mesReferencia} onChange={handleEditInputChange}>
                                    {monthsOptions.map((month) => (
                                        <option key={month.value} value={month.value}>
                                            {month.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="yearSelect">Ano:</Label>
                                <Input className="form-control form-control-alternative" type="select" name="anoReferencia" id="yearSelect" value={editingHours.anoReferencia.toString()} onChange={handleEditInputChange}>
                                    <option value="">Selecione o ano...</option>
                                    {yearsOptions.map((year) => (
                                        <option key={year.value} value={year.value}>
                                            {year.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="dia">Dia:</Label>
                                <Input className="form-control form-control-alternative" type="number" name="dia" id="dia" value={editingHours.dia} onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="horaInicial">Hora Inicial:</Label>
                                <Input className="form-control form-control-alternative" type="time" name="horaInicial" id="horaInicial" value={editingHours.horaInicial} onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="horaFinal">Hora Final:</Label>
                                <Input className="form-control form-control-alternative" type="time" name="horaFinal" id="horaFinal" value={editingHours.horaFinal} onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="horasRealizadas">Horas Realizadas:</Label>
                                <Input className="form-control form-control-alternative" type="number" name="horasRealizadas" id="horasRealizadas" value={editingHours.horasRealizadas.toString()} onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="status">Status:</Label>
                                <Input className="form-control form-control-alternative" type="select" name="status" id="status" value={editingHours.status ? "true" : "false"} onChange={handleEditInputChange}>
                                    <option value="true">Ativo</option>
                                    <option value="false">Inativo</option>
                                </Input>
                            </FormGroup>
                            <div className="d-flex justify-content-end">
                                <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} type="submit" size="m">Salvar</Button>
                            </div>
                        </Form>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default ConsultingHoursRatio;
