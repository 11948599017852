import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { Container, FormGroup, Label, Input, Button } from "reactstrap";
import Header from "../../components/Headers/Header";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { EmpresasContext } from "../../context/EmpresasContext";
import useExcel from "../../context/hooks/useExcel"; 

interface IReportSelection {
    precoCustoMargemCombustivel: boolean;
    vendasTotaisProdutosQuantidade: boolean;
    mixProdutos: boolean;
    precoCustoMargemProdutos: boolean;
}

const Excel = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedNetwork, setSelectedNetwork] = useState<string>("");
    const [selectedEmpresa, setSelectedEmpresa] = useState<string>("");
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [selectedYear, setSelectedYear] = useState<string>("");
    const [reports, setReports] = useState<IReportSelection>({
        precoCustoMargemCombustivel: false,
        vendasTotaisProdutosQuantidade: false,
        mixProdutos: false,
        precoCustoMargemProdutos: false
    });

    const { listEmpresas, getAllNetworks, getEmpresasByNetwokr, listNetworks } = useContext(EmpresasContext);
    const { downloadPrecoCustoMargemCombustivelExcel, downloadVendasTotaisProdutosQuantidadeExcel, downloadMixProdutosExcel, downloadPrecoCustoMargemProdutosExcel } = useExcel(); 

    useEffect(() => {
        getAllNetworks();
    }, []);

    const handleNetworkChange = (networkId: string) => {
        setSelectedNetwork(networkId);
        getEmpresasByNetwokr(networkId);
    };

    const handleCheckboxChange = (reportKey: keyof IReportSelection) => {
        setReports(prev => ({ ...prev, [reportKey]: !prev[reportKey] }));
    };

    const handleDownload = async () => {
        setLoading(true);
        const downloadTasks = [];
        if (reports.precoCustoMargemCombustivel) {
            downloadTasks.push(downloadPrecoCustoMargemCombustivelExcel(Number(selectedEmpresa), Number(selectedYear), selectedMonth));
        }
        if (reports.vendasTotaisProdutosQuantidade) {
            downloadTasks.push(downloadVendasTotaisProdutosQuantidadeExcel(Number(selectedEmpresa), Number(selectedYear), selectedMonth));
        }
        if (reports.mixProdutos) {
            downloadTasks.push(downloadMixProdutosExcel(Number(selectedEmpresa), Number(selectedYear), selectedMonth));
        }
        if (reports.precoCustoMargemProdutos) {
            downloadTasks.push(downloadPrecoCustoMargemProdutosExcel(Number(selectedEmpresa), Number(selectedYear), selectedMonth));
        }

        try {
            await Promise.all(downloadTasks);
        } catch (error) {
            console.error("Erro ao baixar arquivo(s):", error);
        } finally {
            setLoading(false);
        }
    };

    const monthsOptions = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
        .map(month => ({ value: month, label: month }));

    const currentYear = new Date().getFullYear();
    const yearsOptions = Array.from({ length: 10 }, (_, index) => ({ value: `${currentYear - index}`, label: `${currentYear - index}` }));

    return (
        <>
            {loading && <LoadingSpinner />}
            <Header />
            <AdminNavbar brandText="Excel" search={false} titleSearch="" />
            <Container className="mt-4">
                <FormGroup>
                    <Label for="networkSelect">Selecione a rede:</Label>
                    <Input className="form-control-alternative" type="select" name="network" id="networkSelect" value={selectedNetwork} onChange={(e: ChangeEvent<HTMLInputElement>) => handleNetworkChange(e.target.value)}>
                        <option value="">Selecione uma rede...</option>
                        {listNetworks.map(network => (
                            <option key={network.name} value={network.name}>{network.name}</option>
                        ))}
                    </Input>
                </FormGroup>
                {selectedNetwork && (
                    <FormGroup>
                        <Label for="empresaSelect">Selecione a empresa:</Label>
                        <Input className="form-control-alternative" type="select" name="empresa" id="empresaSelect" value={selectedEmpresa} onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedEmpresa(e.target.value)}>
                            <option value="">Selecione uma empresa...</option>
                            {listEmpresas.map(empresa => (
                                <option key={empresa.name} value={empresa.id}>{empresa.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                )}
                {selectedEmpresa && (
                    <>
                        <FormGroup>
                            <Label for="monthSelect">Mês:</Label>
                            <Input className="form-control-alternative" type="select" name="month" id="monthSelect" value={selectedMonth} onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedMonth(e.target.value)}>
                                <option value="">Selecione o mês...</option>
                                {monthsOptions.map(month => (
                                    <option key={month.value} value={month.value}>{month.label}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="yearSelect">Ano:</Label>
                            <Input className="form-control-alternative" type="select" name="year" id="yearSelect" value={selectedYear} onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedYear(e.target.value)}>
                                <option value="">Selecione o ano...</option>
                                {yearsOptions.map(year => (
                                    <option key={year.value} value={year.value}>{year.label}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <FormGroup check>
                            {Object.keys(reports).map(key => (
                                <div key={key}>
                                    <Label check>
                                        <Input type="checkbox" checked={reports[key as keyof IReportSelection]} onChange={() => handleCheckboxChange(key as keyof IReportSelection)} />{' '}
                                        {key.replace(/([A-Z])/g, ' $1').trim()}
                                    </Label>
                                </div>
                            ))}
                        </FormGroup>
                        <Button style={{ color: "#FFF", backgroundColor: "#1c547c", marginTop: "10px" }} onClick={handleDownload}>Download Selecionados</Button>
                    </>
                )}
            </Container>
        </>
    );
};

export default Excel;
