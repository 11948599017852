import { ReactNode, createContext } from "react";
import useHistory, { HistoryType } from "./hooks/useHistory";

interface HistoryContextProviderProps {
    children: ReactNode;
}

export interface HistoryContextData {
    getAllHistories: () => Promise<any>;
    getHistoriesById: (empresaId: number) => Promise<any>;
    historiesList?: HistoryType[];
    setHistoriesList?: (histories: HistoryType[]) => void;
    getPresentationByNetwork?: (id: number) => Promise<any>;
    updateViewingDate?: (id: number) => Promise<any>;
    getAllHistoriesPaged?: (page: number, network?: string) => Promise<any>;
    pageCount: number;
}

const initialState: HistoryContextData = {
    getAllHistories: () => new Promise(() => {}),
    getHistoriesById: (empresaId: number) => new Promise(() => {}),
    historiesList: [],
    setHistoriesList: () => {},
    getPresentationByNetwork: () => new Promise(() => {}),
    updateViewingDate: () => new Promise(() => {}),
    getAllHistoriesPaged: () => new Promise(() => {}),
    pageCount: 0,
};

export const HistoryContext =
    createContext<HistoryContextData>(initialState);

export default function HistoryContextProvider({
    children,
}: HistoryContextProviderProps) {
    const { 
        getAllHistories,
        getHistoriesById,
        historiesList,
        setHistoriesList,
        getPresentationByNetwork,
        updateViewingDate,
        getAllHistoriesPaged,
        pageCount,
    } = useHistory();

    return (
        <HistoryContext.Provider
            value={{
                getAllHistories,
                getHistoriesById,
                historiesList,
                setHistoriesList,
                getPresentationByNetwork,
                updateViewingDate,
                getAllHistoriesPaged,
                pageCount,
            }}
        >
            {children}
        </HistoryContext.Provider>
    );
}
