import { ReactNode, createContext } from "react";
import useLots, { LotsType } from "./hooks/useLots";

interface LotsContextProviderProps {
    children: ReactNode;
}

export interface LotsContextData {
    getAllLotsPaged: (pageNumber: number) => Promise<LotsType[] | null>;
    setListLots: (list: LotsType[]) => void;
    setInitialListLots: (list: LotsType[]) => void;
    deleteLots: (id: number) => void;
    listLots: LotsType[];
    initialListLots: LotsType[];
    pageCount: number;
}

const initialState: LotsContextData = {
    getAllLotsPaged: async () => [],
    setListLots: () => {},
    setInitialListLots: () => {},
    deleteLots: () => {},
    listLots: [],
    initialListLots: [],
    pageCount: 0,
};

export const LotsContext =
    createContext<LotsContextData>(initialState);

export default function LotsContextProvider({
    children,
}: LotsContextProviderProps) {
    const {
        getAllLotsPaged,
        setListLots,
        setInitialListLots,
        deleteLots,
        listLots,
        initialListLots,
        pageCount,
    } = useLots();

    return (
        <LotsContext.Provider
            value={{
                getAllLotsPaged,
                setListLots,
                setInitialListLots,
                deleteLots,
                listLots,
                initialListLots,
                pageCount,
            }}
        >
            {children}
        </LotsContext.Provider>
    );
}
