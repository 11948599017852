import React, { useContext, useEffect } from 'react';
import { Container } from "reactstrap";
import ReactPlayer from "react-player";
import Header from "../components/Headers/Header";
import AdminNavbar from "../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import WhatsappButton from "../components/WhatsappButtom/WhatsappButtom";
import { AuthContext } from '../context/AuthContext'; 
import { EmpresasContext } from '../context/EmpresasContext';

const Home = () => {

  const { objUserTk } = useContext(AuthContext); 
  const { getVideoLink, linkVideo } = useContext(EmpresasContext);

  useEffect(() => {
    if (objUserTk.UserId > 0) {
      getVideoLink(objUserTk.UserId);
    }
  }, []);

  return (
    <>
      <WhatsappButton />
      <LoadingSpinner />
      <Header />
      <AdminNavbar 
        brandText={"Página Inicial"}
        search={false}
        titleSearch={""}
      />

      <div style={{ position: "relative", zIndex: 0, height: "calc(100vh - 56px)", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <ReactPlayer
          url={linkVideo}
          playing={false} 
          controls={true} 
          style={{ position: "inherit", top: "-15%", left: 0, right: 0, bottom: 0, margin: "auto" }}
          width="87%" 
          height="80%" 
        />
      </div>
    </>
  );
};

export default Home;
