import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Button,
  Nav,
  Container,
  Media,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import "./AdminNavbar.css";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { CandidateContext } from "../../../context/CandidateContext";
import { CompanyContext } from "../../../context/CompanyContext";
import { EstadosContext } from "../../../context/EstadosContext";
import { MunicipiosContext } from "../../../context/MunicipiosContext";
import { useMediaQuery } from 'react-responsive';

interface AdminNavbarProps {
  brandText: string;
  showAdvancedFilters?: boolean;
  search: boolean;
  titleSearch: string;
  placeholderSearch?: string;
  placeholderSearch1?: string;
  titleButtonSearch?: string;
  handleButtonSearch?: any
}

const AdminNavbar = (props: AdminNavbarProps) => {
  const { handleLogout, objUserTk, selectTypeUser } = useContext(AuthContext);
  const { candidate, downloadCandidateReport } = useContext(CandidateContext);
  const { company } = useContext(CompanyContext);
  // const { handleGetAllEstados, estadosResponse } = useContext(EstadosContext);
  // const { handleGetAllMunicipios, handleGetMunicipiosPorEstados, municipiosResponse } = useContext(MunicipiosContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    input1: "",
    input2: "",
    state: "",
    educationStatus: "",
    hasCar: false,
    hasPCD: false,
    distance: 0,
    cityReference: "",
  });
  const clearFilters = () => {
    setFormValues({
      input1: "",
      input2: "",
      state: "",
      educationStatus: "",
      hasCar: false,
      hasPCD: false,
      distance: 0,
      cityReference: "",
    });
  };

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const buttonText = isMobile ? "" : props.titleButtonSearch;
  const buttonContent = isMobile ? <i className="fas fa-search" /> : buttonText;

  const [educationStatusOptions] = useState(["Cursando", "Concluído"]);

  const getDefaultPhoto = (selectTypeUser: number, user: any) => {
    if (selectTypeUser === 1 && user.photo) {
      return user.photo;
    } else if (selectTypeUser === 2 && user.photo) {
      return user.photo;
    } else {
      return require("../../../assets/img/theme/profile.png");
    }
  };

  const defaultUserPhoto = getDefaultPhoto(
    selectTypeUser,
    selectTypeUser === 1 ? candidate : company
  );

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setFormValues({
      ...formValues,
      [name]: checked,
    });
  };

  const handleDistanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const distance = parseInt(e.target.value);
    setFormValues({
      ...formValues,
      distance,
    });
  };

  const handleAdvancedSearch = () => {
    props.handleButtonSearch(formValues);
    toggleModal();
  };

  const downloadReport = () => {
    const showOnlyFavorites = localStorage.getItem('showOnlyFavorites') === 'true';
    const favoriteCandidates = showOnlyFavorites ? JSON.parse(localStorage.getItem('favoriteCandidates') || '[]') : undefined;
    downloadCandidateReport(
      formValues.input2,
      formValues.input1,
      formValues.educationStatus,
      formValues.hasCar,
      formValues.hasPCD,
      formValues.distance,
      formValues.state,
      formValues.cityReference,
      favoriteCandidates
    );
  }

  useEffect(() => {
    // handleGetAllMunicipios();
    // handleGetAllEstados();
  }, []);

  return (
    <>
      <Navbar className="nav-style-dev navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          {props.search && (
            <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder={props.titleSearch} type="text" />
                </InputGroup>
              </FormGroup>
            </Form>
          )}

          {props.titleButtonSearch && (
            <div className="d-flex">
              <Form className="form-inline">
                <InputGroup
                  style={{ width: isMobile ? "40%" : "auto" }}
                  className="mr--2 input-group-alternative"
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="input1"
                    value={formValues.input1}
                    onChange={handleChange}
                    placeholder={props.placeholderSearch}
                    type="text"
                  />
                </InputGroup>

                {props.placeholderSearch1 && (
                <div className="my-2 ml--2 bg-light line-search-jobs-inputs" />
                )}
                {props.placeholderSearch1 && (
                  <InputGroup
                    style={{ width: "30%" }}
                    className="input-group-alternative ml--2"
                  >
                    <Input
                      name="input2"
                      value={formValues.input2}
                      onChange={handleChange}
                      placeholder={props.placeholderSearch1}
                      type="text"
                    />
                  </InputGroup>
                )}
                {props.showAdvancedFilters && (
                  <Button
                    className="butom-search-candidate mr--2"
                    onClick={toggleModal}
                    size="sm"
                  >
                    Filtros Avançados
                  </Button>
                )}
                <Button
                  className={
                    selectTypeUser === 1
                      ? "butom-search-jobs"
                      : "butom-search-candidate"
                  }
                  onClick={() => props?.handleButtonSearch(formValues)}
                  size="sm"
                  // style={{ width: isMobile ? "15%" : "20%" }}
                >
                  {buttonContent}
                </Button>
              </Form>
            </div>
          )}

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="card-candidate-content">
                        <div className="card-profile-image-recruiter ">
                          <img
                            className="profile-photo-navbar"
                            alt="Avatar"
                            src={defaultUserPhoto}
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {objUserTk.unique_name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bem vindo!</h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={() => handleLogout()}>
                  <i className="ni ni-user-run" />
                  <span>Sair</span>
                </DropdownItem>
              </DropdownMenu>
              {props.showAdvancedFilters && (
                <div className="text-center mt-4" style={{ position: 'absolute', right: '20px', display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
                  <div className="text-muted small text-white mr-1" style={{ whiteSpace: 'nowrap', fontSize: '0.85rem' }} onClick={downloadReport}>
                    Importar resultados
                    <i className="fas fa-regular fa-file-excel text-white ml-1 mr-4" style={{ fontSize: '0.85rem' }} />
                  </div>
                  {/* <div className="text-muted small text-white mr-1" style={{ whiteSpace: 'nowrap', fontSize: '0.85rem' }} onClick={toggleModal}>
                    Filtros avançados
                    <i className="fas fa-filter text-white ml-1" style={{ fontSize: '0.85rem' }} />
                  </div> */}
                </div>
              )}
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>

      <Modal isOpen={modalOpen} toggle={toggleModal} className="custom-modal">
        <ModalHeader toggle={toggleModal}>Filtros Avançados</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="educationStatus">Escolaridade</Label>
              <Input
                type="select"
                name="educationStatus"
                id="educationStatus"
                value={formValues.educationStatus}
                onChange={handleChange}
              >
                <option value="">Selecione</option>
                {educationStatusOptions.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="hasCar"
                  checked={formValues.hasCar}
                  onChange={handleToggle}
                />
                Possui carro
              </Label>
            </FormGroup>
            <br />
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="hasPCD"
                  checked={formValues.hasPCD}
                  onChange={handleToggle}
                />
                Possui alguma deficiência
              </Label>
            </FormGroup>
            <br />
            {/* <FormGroup>
              <Label className="form-control-label" htmlFor="input-state">Estado</Label>
              <Input
                type="select"
                name="state"
                id="input-state"
                className="form-control"
                value={formValues.state}
                onChange={(e) => {
                  handleChange(e);
                  handleGetMunicipiosPorEstados(e.target.value);
                }}>
                <option value="">Estado</option>
                {estadosResponse.map((estado) => (
                  <option key={estado.codigo_uf} value={estado.uf}>
                    {estado.uf}
                  </option>
                ))}
              </Input>
            </FormGroup> */}
            {/* <FormGroup>
              <Label className="form-control-label" htmlFor="input-city">Cidade</Label>
              <Input
                type="select"
                name="cityReference"
                id="input-city"
                className="form-control"
                value={formValues.cityReference}
                onChange={handleChange}
              >
                <option value="">Cidade</option>
                {Array.isArray(municipiosResponse) && municipiosResponse.map((municipio) => (
                  <option key={municipio.nome} value={municipio.nome}>
                    {municipio.nome}
                  </option>
                ))}
              </Input>
            </FormGroup> */}
            {formValues.cityReference && (
              <FormGroup>
                <Label for="distance">Determine a distância máxima que deseja encontrar seu candidato (km)</Label>
                <input
                  className="input-group-range custom-range"
                  type="range"
                  min="0"
                  max="100"
                  step="10"
                  value={formValues.distance}
                  onChange={handleDistanceChange}
                />
                <div>{formValues.distance} km</div>
              </FormGroup>
            )}
            <div className="mt-4 d-flex justify-content-end">
              <Button onClick={handleAdvancedSearch} color="primary">
                Aplicar Filtros
              </Button>{" "}
              <Button color="secondary" onClick={clearFilters}>
                Limpar Filtros
              </Button>{" "}
              <Button color="secondary" onClick={toggleModal}>
                Cancelar
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

    </>
  );
};

export default AdminNavbar;
