import React, { useContext, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin/Admin";
import AuthLayout from "./layouts/Auth/Auth";
import AuthContextProvider, { AuthContext } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastContext";
import LoadingContextProvider from "./context/LoadingContext";
import EmpresasContextProvider from "./context/EmpresasContext";
import PresentationContextProvider from "./context/PresentationContext";
import FileContextProvider from "./context/FileContext";
import HistoryContextProvider from "./context/HistoryContext";
import HoursContextProvider from "./context/HoursContext";
import ExcelContextProvider from "./context/ExcelContext";
import LotsContextProvider, { LotsContext } from "./context/LotsContext";

const rootElement =
  document.getElementById("root") ?? document.createElement("div");
const root = ReactDOM.createRoot(rootElement);

function CustomRoute() {
  const { authenticated, loadingButton, handleLogout } = useContext(AuthContext);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  setInterval(() => {
    handleLogout();
  }, 100 * 60 * 60 * 1000);

  if (loadingButton) {
    return <Route>Carregando...</Route>;
  }

  const configuration = {
    onUpdate: (registration: ServiceWorkerRegistration) => {
      if (registration && registration.waiting) {
        setShowUpdateModal(true);
      }
    }
  };

  serviceWorkerRegistration.register(configuration);

  // const handleUpdateConfirmation = () => {
  //   serviceWorkerRegistration.unregister();
  //   caches.keys().then(function (cacheNames) {
  //     cacheNames.forEach(function (cacheName) {
  //       caches.delete(cacheName);
  //     });
  //   });
  //   window.location.reload();
  //   handleLogout();
  // };

  const handleUpdateConfirmation = () => {
    // 1. Limpar dados específicos do Local Storage
    localStorage.removeItem('additionalPages');
    localStorage.removeItem('selectedNetwork');
    localStorage.removeItem('generatePages');
    localStorage.removeItem('token');

    // 2. Limpar completamente o Session Storage
    sessionStorage.clear();

    // 3. Limpar os cookies
    document.cookie.split(";").forEach(function (cookie) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });

    // 4. Deletar o Cache Storage
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });

    // 5. Desregistrar o Service Worker
    serviceWorkerRegistration.unregister()
    window.location.reload();
    handleLogout();
  };

  if (!authenticated || loadingButton) {
    return (
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />;
        <Route path="*" element={<Navigate to="/auth/" replace />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="*" element={<Navigate to="/admin/" replace />} />
      </Routes>
      <Modal isOpen={showUpdateModal}>
        <ModalBody>
          Uma nova versão do aplicativo está disponível. Deseja recarregar a página para aplicar as alterações?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateConfirmation}>OK</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

root.render(
  <BrowserRouter>
    <ToastProvider>
      <LoadingContextProvider>
        <AuthContextProvider>
          <EmpresasContextProvider>
            <PresentationContextProvider>
              <HoursContextProvider>
                <HistoryContextProvider>
                  <FileContextProvider>
                    <ExcelContextProvider>
                      <LotsContextProvider>
                        <CustomRoute />
                      </LotsContextProvider>
                    </ExcelContextProvider>
                  </FileContextProvider>
                </HistoryContextProvider>
              </HoursContextProvider>
            </PresentationContextProvider>
          </EmpresasContextProvider>
        </AuthContextProvider>
      </LoadingContextProvider>
    </ToastProvider>
  </BrowserRouter >
);