import { Fragment, useContext, useEffect, useState } from "react";
import { Container, Form, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Header from "../../components/Headers/Header";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import WhatsappButton from "../../components/WhatsappButtom/WhatsappButtom";
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import useUser, { CreateUserType } from "../../context/hooks/useUser";
import MaskedInput from "react-text-mask";

const AddUser = () => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState<CreateUserType>({
        id: 0,
        name: "",
        status: true,
        cnpj: "",
        email: "",
        network: "",
        videoLink: "",
        consultingHours: 0,
        hasConvenience: false,
    });
    const [users, setUsers] = useState<CreateUserType[]>([]);
    const [editingUser, setEditingUser] = useState<CreateUserType | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);

    const [successModal, setSuccessModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);

    const [errorMessage, setErrorMessage] = useState("");

    const [dataSearch, setDataSearch] = useState({ name: "" });

    const {
        createUser,
        getAllUsers,
        updateUser,
        toggleEmpresaStatus,
        deleteEmpresa,
        listUsers,
        initialListUsers,
        pageCount
    } = useUser();

    const toggleSuccessModal = () => setSuccessModal(!successModal);
    const toggleErrorModal = () => setErrorModal(!errorModal);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const parsedValue = value === "true" ? true : value === "false" ? false : value;

        setUserData(prevData => ({
            ...prevData,
            [name]: parsedValue,
        }));
    };


    useEffect(() => {
        setLoading(true);
        getAllUsers(1)
            .then(data => {
                setUsers(data || []);
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar usuários:", error);
                setLoading(false);
            });
    }, []);

    // const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     setLoading(true);
    //     createUser(userData)
    //         .then(newUser => {
    //             setLoading(false);
    //             if (newUser) {
    //                 toggleSuccessModal();

    //                 setUserData({
    //                     id: 0,
    //                     name: "",
    //                     cnpj: "",
    //                     email: "",
    //                     network: "",
    //                     videoLink: "",
    //                     consultingHours: 0,
    //                     hasConvenience: false,
    //                 });

    //                 getAllUsers(currentPage, dataSearch.name)
    //                     .then(data => {
    //                         setUsers(data || []);
    //                     })
    //                     .catch(error => {
    //                         console.error("Erro ao atualizar lista de usuários:", error);
    //                     });
    //             } else {
    //                 toggleErrorModal();
    //             }
    //         })
    //         .catch(error => {
    //             setLoading(false);
    //             console.error("Erro ao criar usuário:", error);
    //             setErrorMessage(error.message || "Erro desconhecido");
    //             toggleErrorModal();
    //         });
    // };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        createUser(userData)
            .then(newUser => {
                setLoading(false);
                toggleSuccessModal();
                setUserData({
                    id: 0,
                    name: "",
                    status: true,
                    cnpj: "",
                    email: "",
                    network: "",
                    videoLink: "",
                    consultingHours: 0,
                    hasConvenience: false,
                });
                getAllUsers(currentPage, dataSearch.name)
                    .then(data => {
                        setUsers(data || []);
                    })
                    .catch(error => {
                        console.error("Erro ao atualizar lista de usuários:", error);
                    });
            })
            .catch(error => {
                setLoading(false);
                console.error("Erro ao criar usuário:", error);
                setErrorMessage(error.message);
                toggleErrorModal();
            });
    };


    const handleEditUser = (user: CreateUserType) => {
        setEditingUser(user);
        setEditModalOpen(true);
    };

    const handleDeleteEmpresa = (empresaId: number) => {
        deleteEmpresa(empresaId)
            .then(() => {
                getAllUsers(currentPage, dataSearch.name);
            })
            .catch(error => {
                setErrorMessage(error.message);
                toggleErrorModal();
            });
    };

    const onChangePaginationCommon = async (e: any, value: any) => {

        await getAllUsers(value, dataSearch.name);
    };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentPage(1);
        setLoading(true);
        getAllUsers(1, dataSearch.name)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingUser) {
            updateUser(editingUser)
                .then(updatedUser => {
                    if (updatedUser) {
                        console.log("Usuário atualizado com sucesso:", updatedUser);
                        toggleSuccessModal();
                        getAllUsers(currentPage, dataSearch.name)
                            .then(data => {
                                setUsers(data || []);
                            })
                            .catch(error => {
                                console.error("Erro ao atualizar lista de usuários:", error);
                            });
                    } else {
                        console.error("Erro ao atualizar usuário.");
                        toggleErrorModal();
                        getAllUsers(currentPage, dataSearch.name)
                            .then(data => {
                                setUsers(data || []);
                            })
                            .catch(error => {
                                console.error("Erro ao atualizar lista de usuários:", error);
                            });
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar usuário:", error);
                    toggleErrorModal();
                });
        }
    };

    const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const parsedValue = value === "true" ? true : value === "false" ? false : value;

        setEditingUser(prevUser => ({
            ...prevUser!,
            [name]: parsedValue,
        }));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataSearch({ ...dataSearch, name: e.target.value });
    };

    const handleToggleStatus = (empresaId: number) => {
        toggleEmpresaStatus(empresaId)
            .then(() => {
                getAllUsers(currentPage, dataSearch.name);
            })
            .catch(error => {
                setErrorMessage(error.message);
                toggleErrorModal();
            });
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <Header />
            <AdminNavbar
                brandText={"Adicionar Empresa"}
                search={false}
                titleSearch={""}
            />
            <Container className="mt-4">
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-first-name">Nome</Label>
                        <Input className="form-control-alternative" type="text" name="name" id="name" value={userData.name} placeholder="Nome" onChange={handleInputChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-first-name">CNPJ</Label>
                        <Input className="form-control-alternative" type="text" name="cnpj" id="cnpj" value={userData.cnpj} placeholder="CNPJ" onChange={handleInputChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-email">Email</Label>
                        <Input className="form-control-alternative" type="email" name="email" id="email" value={userData.email} placeholder="example@example.com" onChange={handleInputChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-email">Rede</Label>
                        <Input className="form-control-alternative" type="text" name="network" id="network" value={userData.network} placeholder="Rede" onChange={handleInputChange} required />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-email">Link do vídeo</Label>
                        <Input className="form-control-alternative" type="text" name="videoLink" id="videoLink" value={userData.videoLink} placeholder="https://www.youtube.com/" onChange={handleInputChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="input-email">Horas de consultoria</Label>
                        <Input className="form-control-alternative" type="number" name="consultingHours" id="consultingHours" value={userData.consultingHours} placeholder="horas" onChange={handleInputChange} />
                    </FormGroup>
                    <FormGroup>
                        <Label className="form-control-label" htmlFor="hasConvenience">Possui conveniência</Label>
                        <div className="ml-3">
                            <Input type="radio" name="hasConvenience" id="hasConvenienceYes" value="true" checked={userData.hasConvenience === true} onChange={handleInputChange} /> <Label for="hasConvenienceYes"><div className="mr-5">Possui</div></Label>
                            <Input type="radio" name="hasConvenience" id="hasConvenienceNo" value="false" checked={userData.hasConvenience === false} onChange={handleInputChange} /> <Label for="hasConvenienceNo">Não possui</Label>
                        </div>
                    </FormGroup>
                    <div className="d-flex justify-content-end">
                        <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} type="submit" size="m">Adicionar empresa</Button>
                    </div>
                </Form>
            </Container>

            <hr />

            <Container className="mt-5">
                <Row>
                    <Col xs="6">
                        <form className="d-flex" onSubmit={handleSearchSubmit}>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="search"
                                id="search"
                                placeholder="Buscar por nome"
                                value={dataSearch.name}
                                onChange={handleSearchChange}
                                style={{ width: "75%" }}
                            />
                            <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} type="submit" size="m">Buscar</Button>
                        </form>
                    </Col>
                </Row>

                {/* <Row className="mt-4">
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>CNPJ</th>
                                    <th>Email</th>
                                    <th>Rede</th>
                                    <th>Horas de consultoria</th>
                                    <th>Possui conveniência</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listUsers.map((user: CreateUserType, index: number) => (
                                    <tr key={index}>
                                        <td>{user.name}</td>
                                        <td>{user.cnpj}</td>
                                        <td>{user.email}</td>
                                        <td>{user.network}</td>
                                        <td>{user.consultingHours}</td>
                                        <td>{user.hasConvenience ? "Sim" : "Não"}</td>
                                        <td>
                                            <Button onClick={() => handleEditUser(user)}><i className="fa-solid fa-pen-to-square"></i></Button>
                                            <Button color="secondary" onClick={() => handleToggleStatus(user.id)}>
                                                {user.status == 1 ? "Desativar" : "Ativar"}
                                            </Button>
                                            <Button color="danger" onClick={() => handleDeleteEmpresa(user.id)}>Excluir</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listUsers.length === 0 && (
                                <div style={{ color: "red" }}>ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row> */}

                <Row className="mt-4">
                    <Col>
                        <div className="table-responsive">
                            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Nome</th>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>CNPJ</th>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Email</th>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Rede</th>
                                        {/* <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Horas de consultoria</th> */}
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Possui conveniência</th>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listUsers.map((user, index) => (
                                        <tr key={index}>
                                            <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.name}</td>
                                            <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.cnpj}</td>
                                            <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.email}</td>
                                            <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.network}</td>
                                            {/* <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.consultingHours}</td> */}
                                            <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.hasConvenience ? "Sim" : "Não"}</td>
                                            <td className="actions-column">
                                                <Button size="sm" style={{ color: "#FFF", backgroundColor: "#1c547c" }} onClick={() => handleEditUser(user)} className="action-button">Editar</Button>
                                                <Button size="sm" color="secondary" onClick={() => handleToggleStatus(user.id)} className="action-button">
                                                    {user.status ? "Desativar" : "Ativar"}
                                                </Button>
                                                <Button size="sm" color="danger" onClick={() => handleDeleteEmpresa(user.id)} className="action-button">Excluir</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {listUsers.length === 0 && (
                                <div style={{ color: "red" }}>ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>


            </Container>

            {/* Modal de sucesso */}
            <Modal isOpen={successModal} toggle={toggleSuccessModal}>
                <ModalHeader toggle={toggleSuccessModal}>Sucesso</ModalHeader>
                <ModalBody>
                    A operação foi concluída com sucesso.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleSuccessModal}>OK</Button>
                </ModalFooter>
            </Modal>

            {/* Modal de erro */}
            <Modal isOpen={errorModal} toggle={toggleErrorModal}>
                <ModalHeader toggle={toggleErrorModal}>Erro</ModalHeader>
                <ModalBody>
                    {errorMessage}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleErrorModal}>OK</Button>
                </ModalFooter>
            </Modal>


            {/* Modal de edição */}
            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)}>
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Editar Usuário</ModalHeader>
                <ModalBody>
                    {editingUser && (
                        <Form onSubmit={handleEditSubmit}>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-first-name">Nome</Label>
                                <Input className="form-control-alternative" type="text" name="name" id="name" value={editingUser?.name || ''} placeholder="Nome" onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-first-name">CNPJ</Label>
                                <Input className="form-control-alternative" type="text" name="cnpj" id="cnpj" value={editingUser?.cnpj || ''} placeholder="CNPJ" onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-email">Email</Label>
                                <Input className="form-control-alternative" type="email" name="email" id="email" value={editingUser?.email || ''} placeholder="Email" onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-password">Senha</Label>
                                <Input className="form-control-alternative" type="password" name="password" id="password" placeholder="Senha" onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-email">Rede</Label>
                                <Input className="form-control-alternative" type="text" name="network" id="network" value={editingUser?.network || ''} placeholder="Rede" onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-email">Link do vídeo</Label>
                                <Input className="form-control-alternative" type="text" name="videoLink" id="videoLink" value={editingUser?.videoLink || ''} placeholder="https://www.youtube.com/" onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-email">Horas de consultoria</Label>
                                <Input className="form-control-alternative" type="number" name="consultingHours" id="consultingHours" value={editingUser?.consultingHours || ''} placeholder="horas" onChange={handleEditInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="hasConvenience">Possui conveniência</Label>
                                <div className="ml-3">
                                    <Input type="radio" name="hasConvenience" id="hasConvenienceYes" value="true" checked={editingUser?.hasConvenience === true} onChange={handleEditInputChange} /> <Label for="hasConvenienceYes"><div className="mr-5">Possui</div></Label>
                                    <Input type="radio" name="hasConvenience" id="hasConvenienceNo" value="false" checked={editingUser?.hasConvenience === false} onChange={handleEditInputChange} /> <Label for="hasConvenienceNo">Não possui</Label>
                                </div>
                            </FormGroup>
                            <div className="d-flex justify-content-end">
                                <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} type="submit" size="m">Salvar alterações</Button>
                            </div>
                        </Form>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default AddUser;
