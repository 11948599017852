import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";

export interface PresentationType {
    id: number;
    name: string;
    generatePages: number[];
    additionalPages: AdditionalPages[];
    hasConvenience: boolean;
}

export interface AdditionalPages {
    index: number;
    title: string;
    content?: string;
    imagem?: string;
}

const usePresentation = () => {
    const { setLoading } = useContext(LoadingContext);
    const [htmlApresentation, setHtmlApresentation] = useState("");
    const showToast = useToast();

    const generatePdf = async (empresas: PresentationType[], month: string, hasFinalConsolidation: boolean, hasFinalConsolidationConveniencia: boolean, hasFinalConsolidationConvenienciaPosto: boolean) => {
        setLoading(true);
        try {
            const response = await api.post('/presentation/generate-pdf', { empresas, month, hasFinalConsolidation, hasFinalConsolidationConveniencia, hasFinalConsolidationConvenienciaPosto }, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ConsolidatedReport.pdf`);
            document.body.appendChild(link);
            link.click();
            // document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            showToast("success", "Apresentação gerada com sucesso!");
        } catch (error) {
            console.error("Erro ao gerar PDF:", error);
            showToast("error", "Houve um erro ao gerar o PDF, tente novamente.", error);
        } finally {
            setLoading(false);
        }
    };

    async function handleGenerateHTML(
        empresas?: PresentationType[],
        month?: string,
        hasFinalConsolidation?: boolean,
        hasFinalConsolidationConveniencia?: boolean,
        hasFinalConsolidationConvenienciaPosto?: boolean
    ): Promise<any | null> {
        console.log("empresas", empresas);
        setLoading(true);
        try {
            let response = await api.post(`/presentation/generate-pdf-html`, { empresas, month, hasFinalConsolidation, hasFinalConsolidationConveniencia, hasFinalConsolidationConvenienciaPosto });
            setLoading(false);
            setHtmlApresentation(response.data);
            return response.data;
        } catch (error) {
            setLoading(false);
            showToast(
                "error",
                "Houve um erro ao gerar apresentação, tente novamente.",
                error
            );
            return null;
        }
    }

    return {
        generatePdf,
        handleGenerateHTML,
        htmlApresentation,
        setHtmlApresentation,
    };
};

export default usePresentation;