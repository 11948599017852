import React, { useContext, useEffect, useState } from "react";
import { Container, Table, Button, Row, Col, Input } from "reactstrap";
import Header from "../../components/Headers/Header";
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useHistory, { HistoryType } from "../../context/hooks/useHistory";

const PresentationHistory = () => {
    const { getAllHistories, historiesList, getPresentationByNetwork, getAllHistoriesPaged, pageCount } = useHistory();
    const [loading, setLoading] = useState(false);
    const [histories, setHistories] = useState<HistoryType[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataSearch, setDataSearch] = useState({ network: "" });

    useEffect(() => {
        async function fetchHistories() {
            setLoading(true);
            try {
                // await getAllHistories();
                getAllHistoriesPaged(1)
                    .then(data => {
                        setHistories(data || []);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error("Erro ao buscar usuários:", error);
                        setLoading(false);
                    });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Erro ao buscar históricos:", error);
            }
        }
        fetchHistories();
    }, []);

    const onChangePaginationCommon = async (e: any, value: any) => {

        await getAllHistoriesPaged(value, dataSearch.network);
    };

    const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentPage(1);
        setLoading(true);
        getAllHistoriesPaged(1, dataSearch.network)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataSearch({ ...dataSearch, network: e.target.value });
    };

    const handleDownload = async (id: number) => {
        const base64Data = await getPresentationByNetwork(id);
        if (base64Data) {
            const fileName = "presentation.pdf";
            const linkSource = base64Data;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };

    return (
        <>
            {loading && <LoadingSpinner />}
            <Header />
            <AdminNavbar brandText={"Histórico de relatórios"} search={false} titleSearch={""} />
            <Container className="mt-4">
                <Row>
                    <Col xs="6">
                        <form className="d-flex" onSubmit={handleSearchSubmit}>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="search"
                                id="search"
                                placeholder="Buscar por rede"
                                value={dataSearch.network}
                                onChange={handleSearchChange}
                                style={{ width: "75%" }}
                            />
                            <Button style={{ color: "#FFF", backgroundColor: "#1c547c" }} type="submit" size="m">Buscar</Button>
                        </form>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <div className="table-responsive">
                            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Rede</th>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Data de Criação</th>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Visualizado</th>
                                        <th style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historiesList.map((histories, index) => (
                                        <tr key={index}>
                                            <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{histories.network}</td>
                                            <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{histories.createdData}</td>
                                            <td style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{histories.viewingDate ? `Visualizado em: ${histories.viewingDate}` : 'Não visualizado'}</td>
                                            <td className="actions-column">
                                                <Button onClick={() => handleDownload(histories.id)} style={{ color: "#FFF", backgroundColor: "#1c547c" }}>
                                                    Baixar
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            {pageCount && (
                                <div className="d-flex justify-content-center mt-4">
                                    <PaginationMaterial
                                        onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                        count={pageCount}
                                    />
                                </div>
                            )}
                            {historiesList.length === 0 && (
                                <div style={{ color: "red" }}>ENCONTRADO NENHUM REGISTRO</div>
                            )}
                        </div>
                    </Col>
                </Row>

                {/* <Table striped>
                    <thead>
                        <tr>
                            <th>Rede</th>
                            <th>Data de Criação</th>
                            <th>Visualizado</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {histories.map(history => (
                            <tr key={history.id}>
                                <td>{history.network}</td>
                                <td>{history.createdData}</td>
                                <td>{history.viewingDate ? `Visualizado em: ${history.viewingDate}` : 'Não visualizado'}</td>
                                <td>
                                    <Button onClick={() => handleDownload(history.id)} style={{ color: "#FFF", backgroundColor: "#1c547c" }}>
                                        Baixar
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table> */}
            </Container>
        </>
    );
};

export default PresentationHistory;
